<p-toast />
<app-spinner [isLoading]="loading"></app-spinner>
<div class="content-prompt">
    <section class="section-prompt">
        <div class="wrapper">
            <div *ngIf="textResponse !== ''">
                <label>
                    <strong>Documentação</strong>
                </label>
                <div *ngIf="!loading">
                    <app-typewriter class="card p-4" [text]="textResponse" [isWrite]="true"></app-typewriter>
                </div>
            </div>

            <div class="feedback-items" *ngIf="textResponse !== '' && !loading">
                <i class="fa fa-undo mr-2 icons-feedback" pTooltip="Regenerate"
                    (click)="regenerateQuestionNoPrompt(userInput)">
                </i>
                <i class="fa fa-thumbs-up mr-2 icons-feedback"
                    *ngIf="satisfactoryAnswer == null || satisfactoryAnswer == true"
                    [ngClass]="satisfactoryAnswer == true ? 'feedback-selected' : ''" pTooltip="Good response"
                    (click)="satisfactoryAnswer == null ? setFeedbackUser(chatId, true) : ''">
                </i>
                <i class="fa fa-thumbs-down mr-2 icons-feedback"
                    *ngIf="satisfactoryAnswer == null || satisfactoryAnswer == false"
                    [ngClass]="satisfactoryAnswer == false ? 'feedback-selected' : ''" pTooltip="Bad response"
                    (click)="satisfactoryAnswer == null ? setFeedbackUser(chatId, false) : ''">
                </i>
                <i class="fa fa-copy mr-2 icons-feedback" pTooltip="Copy" (click)="copyHTMLContent(textResponse)"
                    *ngIf="!isCopy">
                </i>
                <i class="fa fa-check mr-2 icons-feedback" pTooltip="Copy" *ngIf="isCopy">
                </i>
            </div>

            <div class="chat-textarea mt-4">
                <textarea rows="16" pInputTextarea [(ngModel)]="userInput" (keydown.enter)="sendMessageNoPrompt()"
                    placeholder="Informe o código que deseja Documentar">
                </textarea>
            </div>
            <div class="buttons">
                <button class="button-chat p-2 mr-2" (click)="sendMessageNoPrompt()" [disabled]="userInput == ''">
                    <strong>Documentar</strong><i class="fa fa-arrow-up ml-1"></i>
                </button>
                <button class="button-chat" pTooltip="Parar" tooltipPosition="top"
                    [disabled]="textResponse == '' || isStopText" (click)="stopTyping()">
                    <i class="fa fa-stop"></i>
                </button>
            </div>

        </div>
        <div *ngIf="textResponse !== ''" #scroll></div>
    </section>
</div>

<p-dialog header="Header" [(visible)]="visible" [style]="{ width: '60vw' }" [modal]="true" (onHide)="onCloseFeedback()">
    <ng-template pTemplate="header">
        <span class="text-xl font-bold">Conte-nos mais?</span>
    </ng-template>
    <div style="padding: 2rem 3rem 0 3rem">
        <div class="dialog-inputs mt-2">
            <input pInputText class="col-4 mr-2 p-2 input-feedback" type="text" value="O código estava incorreto"
                [readonly]="true" (click)="setFeedbackMessage('O código estava incorreto')" />
            <input pInputText class="col-4 mr-2 p-2 input-feedback" type="text" value="Recusei quando não deveria"
                [readonly]="true" (click)="setFeedbackMessage('Recusei quando não deveria')" />
            <input pInputText class="col-4 p-2 input-feedback" type="text" value="Não gostei do estilo"
                (click)="setFeedbackMessage('Não gostei do estilo')" />
        </div>
        <div class="dialog-inputs mt-2 mb-4">
            <input pInputText class="col-6 mr-2 p-2 input-feedback" type="text"
                value="Não segui as instruções completamente" [readonly]="true"
                (click)="setFeedbackMessage('Não segui as instruções completamente')" />
            <input pInputText class="col-4 mr-2 p-2 input-feedback" type="text" value="Não está correto de fato"
                [readonly]="true" (click)="setFeedbackMessage('Não está correto de fato')" />
            <input pInputText class="col-2 p-2 input-feedback" type="text" value="Outro..." (click)="isOther = true"
                [readonly]="true" />
        </div>
    </div>
    <div class="dialog-justification mt-5" *ngIf="isOther">
        <label class="col-10">Justificativa</label>
        <textarea pInputTextarea class="textarea-feedback" [autoResize]="true"
            placeholder="(Opcional) Sinta-se à vontade para adicionar detalhes específicos"
            [(ngModel)]="userFeedbackInput">
        </textarea>
    </div>
    <ng-template class="dialog-footer" pTemplate="footer">
        <button [class]="userFeedbackInput == '' && !isOther ? 'button-modal-disabled' : 'button-modal'"
            (click)="setResponseFeedback()" [disabled]="userFeedbackInput == '' && !isOther">
            <i class="fa fa-share mr-1"></i> <span>Enviar</span>
        </button>
    </ng-template>
</p-dialog>
