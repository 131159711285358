import { Component, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { Dialog } from "primeng/dialog";

@Component({
    selector: "app-layout",
    styleUrls: ["./layout.component.less"],
    template: `
        <p-dialog
            #dialog
            header="Header"
            [modal]="true"
            [(visible)]="visibleCodeModal"
            [style]="{ width: '50vw' }"
            (onShow)="maximizeDialog()"
            (onHide)="onClose()"
        >
            <div>
                <app-topbar></app-topbar>
                <div>
                    <router-outlet></router-outlet>
                </div>
            </div>
            <app-footer></app-footer>
        </p-dialog>
    `,
})
export class LayoutComponent {
    overlayMenuOpenSubscription: Subscription;
    menuOutsideClickListener: any;
    visibleCodeModal: boolean = true;
    @ViewChild("dialog") dialog!: Dialog;

    constructor(public router: Router) { }

    maximizeDialog() {
        if (this.dialog) {
            this.dialog.maximize();
        }
    }

    onClose() {
        this.router.navigateByUrl(`/app/main/myProfile`);
    }

    ngOnDestroy() {
        if (this.overlayMenuOpenSubscription) {
            this.overlayMenuOpenSubscription.unsubscribe();
        }

        if (this.menuOutsideClickListener) {
            this.menuOutsideClickListener();
        }
    }
}
