<p-toast />
<app-spinner [isLoading]="loading"></app-spinner>
<div class="content-prompt">
    <section class="section-prompt">
        <div class="wrapper">
            <div class="card content-card">
                <div class="top-items p-4">
                    <label (click)="showDialog()">
                        Atenção <i class="fa fa-info-circle icons" pTooltip="Informativo" tooltipPosition="right">
                        </i>
                    </label>
                    <div class="d-flex" *ngIf="file">
                        <p-checkbox class="mr-2" [(ngModel)]="isUml" label="Diagrama UML" [binary]="true"
                            (click)="setTypeFile(true); isTechnicalSpecification = false">
                        </p-checkbox>
                        <p-checkbox class="mr-2" [(ngModel)]="isTechnicalSpecification" label="Especificação Funcional"
                            [binary]="true" (click)="setTypeFile(false); isUml = false">
                        </p-checkbox>
                    </div>
                </div>

                <div class="col-12 mb-4 mt-4" *ngIf="textResponse !== ''">
                    <label style="font-weight: 600;">Arquivos Gerados</label>
                    <app-archive-list [archives]="items"></app-archive-list>
                </div>
            </div>
            <div class="prompt-feedback" *ngIf="textResponse !== ''">
                <app-typewriter class="card p-4" [text]="textResponse" [isWrite]="true"></app-typewriter>
                <div class="feedback-items p-3">
                    <i class="fa fa-thumbs-up mr-2 icons-feedback"
                        *ngIf="satisfactoryAnswer == null || satisfactoryAnswer == true"
                        [ngClass]="satisfactoryAnswer == true ? 'feedback-selected' : ''" pTooltip="Good response"
                        (click)="satisfactoryAnswer == null ? setFeedbackUser(chatId, true) : ''">
                    </i>
                    <i class="fa fa-thumbs-down mr-2 icons-feedback"
                        *ngIf="satisfactoryAnswer == null || satisfactoryAnswer == false"
                        [ngClass]="satisfactoryAnswer == false ? 'feedback-selected' : ''" pTooltip="Bad response"
                        (click)="satisfactoryAnswer == null ? setFeedbackUser(chatId, false) : ''">
                    </i>
                    <i class="fa fa-copy mr-2 icons-feedback" pTooltip="Copy" (click)="copyHTMLContent(textResponse)"
                        *ngIf="!isCopy">
                    </i>
                    <i class="fa fa-check mr-2 icons-feedback" pTooltip="Copy" *ngIf="isCopy">
                    </i>
                </div>
            </div>
            <div class="card-files card mt-4" *ngIf="file">
                <div class="card-file-items">
                    <i class="fa fa-file-pdf mr-2 icon-file"></i>
                    <div class="tile-file mr-2">
                        <strong>{{ file.name }}</strong> <br>
                        {{ formatSize(file.size) }}
                    </div>
                    <i class="fa fa-times icon-time" (click)="removeFile()"></i>
                </div>
            </div>
            <div class="chat-question" [ngClass]="file == null ? 'mt-5' : ''">
                <div class="chat-textarea">
                    <textarea pInputTextarea rows="12" [(ngModel)]="userInput" placeholder="Digite sua mensagem">
                    </textarea>
                </div>
            </div>
            <div class="buttons">
                <p-fileUpload class="mr-2" (onSelect)="onUploaded($event)" [multiple]="false" accept="application/pdf"
                    maxFileSize="5242880" cancelLabel="Cancelar" mode="basic" [showUploadButton]="false" name="demo[]"
                    chooseLabel="Upload" *ngIf="isUpload">
                </p-fileUpload>
                <button class="button-chat mr-2" pTooltip="Enviar" tooltipPosition="top"
                    [disabled]="(userInput == '' && file == null) || loading" (click)="readDocAndGenerateCode()">
                    <i class="fa fa-arrow-up"></i>
                </button>
                <button class="button-chat" pTooltip="Parar" tooltipPosition="top"
                    [disabled]="textResponse == '' || isStopText" (click)="stopTyping()">
                    <i class="fa fa-stop"></i>
                </button>
            </div>
        </div>
    </section>
</div>

<p-dialog header="Header" [(visible)]="visible" [style]="{ width: '60vw' }" [modal]="true" (onHide)="onCloseFeedback()">
    <ng-template pTemplate="header">
        <span class="text-xl font-bold">Conte-nos mais?</span>
    </ng-template>
    <div style="padding: 2rem 3rem 0 3rem">
        <div class="dialog-inputs mt-2">
            <input pInputText class="col-4 mr-2 p-2 input-feedback" type="text" value="O código estava incorreto"
                [readonly]="true" (click)="setFeedbackMessage('O código estava incorreto')" />
            <input pInputText class="col-4 mr-2 p-2 input-feedback" type="text" value="Recusei quando não deveria"
                [readonly]="true" (click)="setFeedbackMessage('Recusei quando não deveria')" />
            <input pInputText class="col-4 p-2 input-feedback" type="text" value="Não gostei do estilo"
                (click)="setFeedbackMessage('Não gostei do estilo')" />
        </div>
        <div class="dialog-inputs mt-2 mb-4">
            <input pInputText class="col-6 mr-2 p-2 input-feedback" type="text"
                value="Não segui as instruções completamente" [readonly]="true"
                (click)="setFeedbackMessage('Não segui as instruções completamente')" />
            <input pInputText class="col-4 mr-2 p-2 input-feedback" type="text" value="Não está correto de fato"
                [readonly]="true" (click)="setFeedbackMessage('Não está correto de fato')" />
            <input pInputText class="col-2 p-2 input-feedback" type="text" value="Outro..." (click)="isOther = true"
                [readonly]="true" />
        </div>
    </div>
    <div class="dialog-justification mt-5" *ngIf="isOther">
        <label class="col-10">Justificativa</label>
        <textarea pInputTextarea class="textarea-feedback" [autoResize]="true"
            placeholder="(Opcional) Sinta-se à vontade para adicionar detalhes específicos"
            [(ngModel)]="userFeedbackInput">
        </textarea>
    </div>
    <ng-template class="dialog-footer" pTemplate="footer">
        <button [class]="userFeedbackInput == '' && !isOther ? 'button-modal-disabled' : 'button-modal'"
            (click)="setResponseFeedback()" [disabled]="userFeedbackInput == '' && !isOther">
            <i class="fa fa-share mr-1"></i> <span>Enviar</span>
        </button>
    </ng-template>
</p-dialog>

<app-modal-information [visible]="showModal"></app-modal-information>
