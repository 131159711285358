import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class EventEmitterService {
    private clickedButton: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(true);

    setClickButton(indice: boolean) {
        this.clickedButton.next(indice);
    }

    public getClickButton(): Observable<boolean> {
        return this.clickedButton.asObservable();
    }
}
