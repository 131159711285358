<!-- <div class="card d-flex justify-content-center">
    <p-carousel [value]="archives" [numVisible]="3" [numScroll]="3" [circular]="false"
        [responsiveOptions]="responsiveOptions">
        <ng-template let-archive pTemplate="item">
            <div class="border-1 surface-border border-round m-2 p-3">
                <div class="d-flex align-items-center">
                    <div class="mt-0 font-semibold">
                        {{ archive.label }}
                    </div>
                    <span pTooltip="Download" tooltipPosition="top">
                        <p-button icon="fa fa-download" styleClass="ml-4 p-2" (click)="downloadTextFile(archive)" />
                    </span>
                </div>
            </div>
        </ng-template>
    </p-carousel>
</div> -->
<div class="card-files card">
    <div class="card-file-items mr-3" *ngFor="let file of archives">
        <i class="fa fa-file mr-2 icon-file"></i>
        <div class="title-file-icon">
            <strong>{{ file.label }}</strong>
            <i class="fa fa-download icon-download" pTooltip="Download" tooltipPosition="top"
                (click)="downloadTextFile(file)">
            </i>
        </div>
    </div>
</div>
