import { Component, Injector, OnInit } from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";
import { ComponentPackagesDto, ComponentsAndTheirObjectsDto, ComponentsForIntegratedTestsDto, EmailsForIntegratedTestsDto, GetEmailsForIntegratedTestsDto, GetTestsAndTheirStatusDto, IntegratedTestsServiceProxy, PackageObjectsDto } from "@shared/service-proxies/service-proxies";
import { Router } from "@angular/router";
import { TreeNode } from "primeng/api";

@Component({
    selector: "app-integrated-tests",
    templateUrl: "./integrated-tests.component.html",
    styleUrls: ["./integrated-tests.component.less"],
})
export class IntegratedTestsComponent extends AppComponentBase implements OnInit
{
    getEmailsForIntegratedTestsDto: GetEmailsForIntegratedTestsDto = new GetEmailsForIntegratedTestsDto;
    componentsForIntegratedTestsDto: ComponentsForIntegratedTestsDto [] = [];
    componentsList: ComponentsForIntegratedTestsDto [] = [];
    component: string[] = [];
    itensForTest: TreeNode[] = [];
    itensForTestSelected: TreeNode[] = [];
    itensIntegrated: ComponentsAndTheirObjectsDto[] = [];
    historyIntegration: GetTestsAndTheirStatusDto[] = [];

    constructor(
        injector: Injector,
        private router: Router,
        private _integratedTestsAppService: IntegratedTestsServiceProxy
    ) {
        super(injector);
    }

    async ngOnInit() {
        await this.getEmailsIntegratedTest();
        await this.getAllComponents();
        await this.loadItensForTest();
        await this.getAllHistoryIntegration();
    }

    validarListEmails() {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        this.getEmailsForIntegratedTestsDto.userEmails = this.getEmailsForIntegratedTestsDto.userEmails.filter(email => emailRegex.test(email));
    }

    async addEmails(){
        if (this.getEmailsForIntegratedTestsDto != null || this.getEmailsForIntegratedTestsDto != undefined && 
            this.getEmailsForIntegratedTestsDto.userEmails.length > 0){
            let emails = '';
            this.getEmailsForIntegratedTestsDto.userEmails.forEach(element => {
                emails = emails + element + ';';
            });

            let emailsForIntegratedTestsDto: EmailsForIntegratedTestsDto = new EmailsForIntegratedTestsDto();
            emailsForIntegratedTestsDto.userEmails = emails;
            emailsForIntegratedTestsDto.performTestsMonthly = this.getEmailsForIntegratedTestsDto.performTestsMonthly;

            this._integratedTestsAppService.insertUpdateEmailsForIntegratedTests(emailsForIntegratedTestsDto)
                .subscribe(() => {
                    this.notify.success('Parâmetros salvos.');
                    return;
                });
        }
    }

    async getEmailsIntegratedTest() {
        await this._integratedTestsAppService.getEmailsForIntegratedTests()
                  .subscribe(res => {
                        this.getEmailsForIntegratedTestsDto.userEmails = res.userEmails;
                        this.getEmailsForIntegratedTestsDto.performTestsMonthly = res.performTestsMonthly;
            })
            .add();
    }

    async addComponent() {
        if (this.component == null ||  this.component == undefined || this.component.length == 0)
            {
                this.notify.warn('Informe o componente!');
                return;
            }
        
        console.log(this.component);

        this.component.forEach(comp => {
            let componentsForIntegratedTestsDto: ComponentsForIntegratedTestsDto = new ComponentsForIntegratedTestsDto();
            componentsForIntegratedTestsDto.component = comp;

            this.componentsForIntegratedTestsDto.push(componentsForIntegratedTestsDto);         
        });

        await this._integratedTestsAppService.insertComponentsForIntegratedTests(this.componentsForIntegratedTestsDto)
                  .subscribe(res => {
                    this.componentsForIntegratedTestsDto = [];
                    this.component = [];
                    this.notify.success('Componente adicionado com sucesso.');
                    this.getAllComponents();
                  }).add();
    }

    async getAllComponents() {
        await this._integratedTestsAppService.getComponentsForIntegratedTests()
                  .subscribe(res => {
                    this.componentsList = res;
                  }).add();
    }

    async deleteComponentItem(i: number, component: ComponentsForIntegratedTestsDto) {
        await this._integratedTestsAppService.deleteComponent(component.id)
            .subscribe(res => {
                this.componentsList.splice(i, 1);
                this.notify.success(this.l('SuccessfullyRemoved'));
                this.getAllComponents();
            }).add();
    }

    async syncFromSap() {
        await this._integratedTestsAppService.synchronizeObjectsByComponent()
                  .subscribe(res => {
                    this.notify.success('Sincronização solicitada com sucesso');
                  }).add();
    }

    async loadItensForTest() {
        this._integratedTestsAppService.getComponentsAndTheirObjects()
            .subscribe((data: any) => {
                this.itensForTest = data.map((item: any) => ({
                label: item.component, 
                data: item,
                selectable: true,
                children: item.packages?.map((pack: any) => ({
                    label: pack.package,
                    data: pack,
                    selectable: true,
                    children: pack.objects?.map((obj: any) => ({
                        label: obj.title,
                        data: obj,
                        selectable: true,
                        children: []
                        })) || []
                    })) || []
                }));
            }).add();
    }

    onSelectionChange(event: any): void {
        console.log('Seleção atualizada:', event);
    }

    async submitForIntegratedTesting() {
        if (this.itensForTestSelected == null || this.itensForTestSelected.length == 0){
            this.notify.warn('Selecione ao menos um item para envio.');
            return;
        }

        let guids: string[] = [];
        this.itensForTestSelected.forEach(element => {
            guids.push(element.data?.id);
        });
    
        console.log("this.itensForTestSelected ===>", guids);
        await this._integratedTestsAppService.insertObjectsForIntegratedTests(guids)
                  .subscribe(res => {
                    this.notify.success('Testes enviados com sucesso.');
                  }).add();
    }

    async getAllHistoryIntegration() {
        await this._integratedTestsAppService.getTestsAndTheirStatus()
                  .subscribe(res => {
                    this.historyIntegration = res;
                  }).add();
    }
}
