import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: "app-typewriter",
    templateUrl: "./typewriter.component.html",
    styleUrls: ["./typewriter.component.less"],
})
export class TypewriterComponent implements OnInit {
    @Input() text: string = "";
    @Input() isWrite: boolean = false;
    displayText: string = "";
    typingSpeed: number = 10;
    typingInterval: any;

    ngOnInit(): void {
        this.startTyping();
    }

    startTyping() {
        if (this.isWrite) {
            let index = 0;
            this.typingInterval = setInterval(() => {
                this.displayText += this.text[index];
                index++;

                if (index >= this.text.length) {
                    clearInterval(this.typingInterval);
                }
            }, this.typingSpeed);
        }
    }

    stopTyping() {
        if (this.typingInterval) {
            clearInterval(this.typingInterval);
            this.typingInterval = null;
        }
    }
}
