<div class="kt-login__form">
    <div class="kt-subheader kt-grid__item">
        <div class="kt-subheader__main">
            <h3 class="kt-subheader__title">
                <i class="flaticon-graphic mr-2"></i>
                <span>Relatórios</span>
            </h3>
            <span class="kt-subheader__separator kt-subheader__separator--v"></span>
            <span class="kt-subheader__desc">
                Selecione o relatório que deseja visualizar no menu abaixo
            </span>
        </div>
        <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper">
            </div>
        </div>
    </div>
    <div class="mb-5" *ngIf="appSession.tenantId == null || !noDashs">
        <p-menubar [model]="items"></p-menubar>
    </div>
    <div class="p-fluid p-float-label col-2 mb-3 mt-4" *ngIf="viewDropDate()">
        <p-dropdown [options]="datesOptions" optionLabel="label" optionValue="value" [showClear]="false"
            placeholder="Selecione a Data" [(ngModel)]="selectedDate" (ngModelChange)="setDateDrop()">
        </p-dropdown>
        <label class="ml-1">Datas de Extração</label>
    </div>
    <div class="mt-4" *ngIf="(appSession.tenantId == null || !noDashs) && !isIntegratedTests">
        <iframe class="col-12" frameborder="0" height="600" allowtransparency [src]="urlReport"></iframe>
    </div>

    <div class="mt-4" *ngIf="appSession.tenantId != null && isIntegratedTests">
        <div class="p-fluid p-float-label col-2 mb-3 mt-4">
            <p-dropdown [options]="datesTestsOptions" optionLabel="label" optionValue="value" [showClear]="false"
                placeholder="Selecione a Data" [(ngModel)]="selectedTestsDate" (ngModelChange)="setDateTestsDrop(true)">
            </p-dropdown>
            <label class="ml-1">Datas de Execução</label>
        </div>
        <iframe class="col-12" frameborder="0" height="400" allowtransparency [src]="urlReport1"></iframe>
        <div class="p-fluid p-float-label col-2 mb-3 mt-4">
            <p-dropdown [options]="datesTestsObjectsOptions" optionLabel="label" optionValue="value" [showClear]="false"
                placeholder="Selecione a Data" [(ngModel)]="selectedTestsObjectsDate"
                (ngModelChange)="setDateTestsDrop(false)">
            </p-dropdown>
            <label class="ml-1">Datas de Execução</label>
        </div>
        <iframe class="col-12" frameborder="0" height="500" allowtransparency [src]="urlReport2"></iframe>
    </div>

    <div *ngIf="appSession.tenantId != null && noDashs">
        <span class="no-dash">Nenhum gráfico ativo</span>
    </div>
</div>
