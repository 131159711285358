import { Component, Input } from "@angular/core";
import { SpinnerService } from "@shared/services/spinner.service";

@Component({
    selector: "app-spinner",
    templateUrl: "./spinner.component.html",
    styleUrls: ["./spinner.component.less"],
})
export class SpinnerComponent {
    @Input() isLoading: boolean = false;

    constructor(private spinnerService: SpinnerService) {
        this.spinnerService.isLoading.subscribe((value) => {
            this.isLoading = value;
        });
    }
}
