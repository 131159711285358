<form #integratedTestForm="ngForm" id="integratedTest">
    <div [@routerTransition] class="overflow-auto">
        <div class="kt-subheader kt-grid__item">
            <div class="kt-subheader__main">
                <h3 class="kt-subheader__title">
                    <span>Testes Integrados</span>
                </h3>
                <span class="kt-subheader__separator kt-subheader__separator--v"></span>
                <span class="kt-subheader__desc">
                    Gerenciamento de testes integrados
                </span>
            </div>
        </div>
        <div class="kt-content">
            <div class="kt-portlet kt-portlet--mobile">
                <div class="kt-portlet__body">
                    <tabset>
                        <tab heading="Configurações de Testes">
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-8">
                                        <label for="List_Email_Integrated_Test">Emails</label>
                                        <ng-select [items]="getEmailsForIntegratedTestsDto.userEmails" [multiple]="true"
                                            #List_Email_Integrated_Test="ngModel" [(ngModel)]="getEmailsForIntegratedTestsDto.userEmails"
                                            [addTag]="true" name="listEmailIntegratedTest" 
                                            [ngClass]="{'is-invalid': List_Email_Integrated_Test.invalid, 'is-valid': !List_Email_Integrated_Test.invalid}"
                                            (change)="validarListEmails()">
                                        </ng-select>
                                    </div>
                                    <div class="col-md-3 d-flex justify-content-center align-items-center">
                                        <label for="perform-test-monthly" class="kt-checkbox m-0">
                                            <input id="perform-test-monthly" type="checkbox" name="PerfomrTestsMonthly"
                                                [(ngModel)]="getEmailsForIntegratedTestsDto.performTestsMonthly">
                                            Executar testes automático mensalmente
                                            <span></span>
                                        </label>
                                    </div>
                                    <div class="col-md-1 d-flex justify-content-center align-items-center mt-2">
                                        <button class="btn btn-primary" type="submit" (click)="addEmails()">
                                            <i class="la la-floppy-o"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-8">
                                        <label for="integratedTest-Component">Componente</label>
                                        <ng-select [items]="component" [multiple]="true"
                                        #integratedTestComponent="ngModel" [(ngModel)]="component"
                                        [addTag]="true" name="integratedTestComponent">
                                    </ng-select>
                                    </div>
                                    <div class="col-md-2 d-flex justify-content-left align-items-center mt-4">
                                        <button class="btn btn-primary" type="submit" (click)="addComponent()">
                                            <i class="fa fa-plus"></i>
                                        </button>
                                    </div>
                                    <div class="col-md-2 d-flex justify-content-left align-items-center mt-4">
                                        <button class="btn btn-primary" type="submit" (click)="syncFromSap()">
                                            <i class="fas fa-sync"></i> Sincronizar com SAP
                                        </button>
                                    </div>
                                </div>
                            </div>                           
                            <div class="form-group">
                                <div id="component-list" *ngIf="this.componentsList.length > 0; else emptyApprover">
                                    <div class="list-head list-row">
                                        <div class="list-item item-component"><span>Componente</span></div>
                                        <div class="list-item item-action"><span>Ação</span></div>
                                    </div>
                                    <div class="list-body list-row" *ngFor="let component of componentsList; let i = index">
                                        <div class="list-item item-component"><span>{{component.component}}</span></div>
                                        <div class="list-item item-action">
                                            <span class="delete-item" (click)="deleteComponentItem(i, component)" tooltip="{{l('Delete')}}"><i class="fas fa-trash"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <ng-template #emptyApprover>
                                    <h4 class="empty">Não há componente cadastrado</h4>
                                </ng-template>
                            </div>
                        </tab>
                        <tab heading="Solicitar Testes">
                            <div class="form-group d-flex justify-content-end">
                                <div class="col-md-2 d-flex justify-content-end align-items-center mt-4">
                                    <button class="btn btn-primary btn-sm" type="submit" (click)="submitForIntegratedTesting()">
                                        <i class="fas fa-sync"></i> Enviar para teste integrado
                                    </button>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="itensForTest">Selecione os itens para teste</label>
                                <p-tree [value]="itensForTest" selectionMode="checkbox" [(selection)]="itensForTestSelected" 
                                        (selectionChange)="onSelectionChange($event)">
                                    <ng-template let-node pTemplate="default">
                                      {{ node.label }}
                                    </ng-template>
                                  </p-tree>  
                            </div>
                        </tab>
                        <tab heading="Histórico de Integração"> 
                            <div class="form-group d-flex justify-content-end">
                                <div class="col-md-1 d-flex justify-content-center align-items-center mt-2">
                                    <button class="btn btn-primary" type="submit" (click)="getAllHistoryIntegration()">
                                        <i class="fas fa-sync"></i>
                                    </button>
                                </div>  
                            </div>                   
                            <div class="form-group">
                                <div id="component-list" *ngIf="this.historyIntegration.length > 0; else emptyApprover">
                                    <div class="list-head list-row">
                                        <div class="list-item item-component"><span>Número do Teste</span></div>
                                        <div class="list-item item-component"><span>Data da execução</span></div>
                                        <div class="list-item item-componentStatus"><span>Status</span></div>
                                    </div>
                                    <div class="list-body list-row" *ngFor="let history of historyIntegration; let i = index">
                                        <div class="list-item item-component"><span>{{history.testNumber}}</span></div>
                                        <div class="list-item item-component"><span>{{history.executionDate}}</span></div>
                                        <div class="list-item item-componentStatus" title="{{history.errorMessage}}"><span>{{history.status}}</span></div>
                                    </div>
                                </div>
                                <ng-template #emptyApprover>
                                    <h4 class="empty">Nenhum histórico encontrado</h4>
                                </ng-template>
                            </div>
                        </tab>
                    </tabset>
                </div>
            </div>
        </div>   
    </div>
</form>
