// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@use './variables/_common';
@use './_mixins';
@use './_topbar';
@use './_footer';`, "",{"version":3,"sources":["webpack://./src/assets/layout/layout.scss"],"names":[],"mappings":"AAAA,0BAA0B;AAC1B,gBAAgB;AAChB,gBAAgB;AAChB,gBAAgB","sourcesContent":["@use './variables/_common';\n@use './_mixins';\n@use './_topbar';\n@use './_footer';"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
