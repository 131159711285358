<div class="card">
    <p-tabView styleClass="tabview-custom">
        <p-tabPanel>
            <ng-template pTemplate="header">
                <i class="fa fa-comments mr-1"></i>
                <span>Bate-Papo</span>
            </ng-template>
            <p-tree [value]="treeData" [(selection)]="selectedFeatures" selectionMode="checkbox"
                (onNodeSelect)="nodeSelect($event)" [propagateSelectionUp]="false">
                <ng-template let-node pTemplate="default">
                    <span style="margin-right: 5px;">{{node.label}}</span>
                    <input type="{{node.data.inputType.validator.name === 'NUMERIC' ? 'number' : 'text'}}"
                        [attr.name]="node.data.name" *ngIf="node.data.inputType.name === 'SINGLE_LINE_STRING'"
                        [(ngModel)]="node.value" (change)="onInputChange(node)">
                    <select *ngIf="node.data.inputType.name === 'COMBOBOX'" [(ngModel)]="node.value"
                        (change)="onDropdownChange(node)">
                        <option *ngFor="let item of node.data.inputType.itemSource.items" [value]="item.value">
                            {{item.displayName}}
                        </option>
                    </select>
                </ng-template>
            </p-tree>
        </p-tabPanel>
        <p-tabPanel>
            <ng-template pTemplate="header">
                <i class="fa fa-laptop mr-1"></i>
                <span>QACODAI</span>
            </ng-template>
            <p-progressSpinner *ngIf="loading"></p-progressSpinner>
            <div class="d-flex flex-column gap-2" *ngIf="!loading">
                <p-checkbox class="mb-4 mt-2" [(ngModel)]="iaConfig.isEnabled" label="Habilitar QACODAI" [binary]="true"
                    (click)="activeDesactiveIa()">
                </p-checkbox>
                <p-checkbox class="mb-2" [(ngModel)]="iaConfig.codeGenerate" label="Gerar Código" [binary]="true"
                    *ngIf="isIaConfig">
                </p-checkbox>
                <p-checkbox class="mb-2" [(ngModel)]="iaConfig.documentation" label="Gerar Documentação" [binary]="true"
                    *ngIf="isIaConfig">
                </p-checkbox>
                <p-checkbox class="mb-2" [(ngModel)]="iaConfig.codeReview" label="Gerar Revisão de código"
                    [binary]="true" *ngIf="isIaConfig">
                </p-checkbox>
                <!-- <p-checkbox class="mb-2" [(ngModel)]="iaConfig.classDiagramReading" [binary]="true"
                    label="Leitura de Diagrama de Classe" *ngIf="isIaConfig">
                </p-checkbox>
                <p-checkbox class="mb-2" [(ngModel)]="iaConfig.unitTestGeneration" label="Gerar Teste Unitário"
                    [binary]="true" *ngIf="isIaConfig">
                </p-checkbox> -->
                <div class="flex-auto" *ngIf="isIaConfig">
                    <label class="mr-2">Quantidade de Usuários:</label>
                    <p-inputNumber [(ngModel)]="iaConfig.numberOfUsers" mode="decimal" [min]="0" [max]="50">
                    </p-inputNumber>
                </div>
            </div>
            <p-button (click)="saveSelections()" icon="fa fa-save" label="Salvar" *ngIf="!loading"></p-button>
        </p-tabPanel>
        <p-tabPanel>
            <ng-template pTemplate="header">
                <i class="fa fa-laptop mr-1"></i>
                <span>Testes Integrados</span>
            </ng-template>
            <p-progressSpinner *ngIf="loading"></p-progressSpinner>
            <div class="d-flex flex-column gap-2" *ngIf="!loading">
                <p-checkbox class="mb-4 mt-2" [(ngModel)]="integratedTestConf.isEnabled" label="Habilitar Testes Integrados" [binary]="true">
                </p-checkbox>
            </div>
            <p-button (click)="saveIntegrationTest()" icon="fa fa-save" label="Salvar" *ngIf="!loading"></p-button>
        </p-tabPanel>
    </p-tabView>
</div>
