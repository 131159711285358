import { Component, Injector, OnInit } from "@angular/core";
import { MenuItem } from "primeng/api";
import { Router } from "@angular/router";
import {
    PromptAIConfigDto,
    PromptAIConfigServiceProxy,
} from "@shared/service-proxies/service-proxies";
import { AppComponentBase } from "@shared/common/app-component-base";
import { EventEmitterService } from "../../service/event-emmiter.service";

@Component({
    selector: "app-topbar",
    styleUrls: ["../../../../../assets/layout/_topbar.scss"],
    template: `
        <div class="layout-topbar">
            <div class="layout-topbar-logo-container">
                <a class="layout-topbar-logo">
                    <i (click)="openClosedSidebar()" class="fa fa-bars mr-4" *ngIf="isGenerateCode">
                    </i>
                    <span style="font-weight: 500; font-size: 1.1rem;">
                        <i class="fa fa-laptop-code mr-2"></i> QACodai -
                        {{ promptLabel }}
                    </span>
                </a>
            </div>
            <div class="layout-topbar-actions">
                <div class="layout-config-menu">
                    <div class="layout-topbar-menu-content">
                        <p-splitButton
                            label="Prompts"
                            [model]="items"
                            outlined
                        />
                    </div>
                    <div
                        class="layout-topbar-menu-content"
                        style="cursor: pointer;"
                    >
                        <i class="fa fa-times" (click)="onClose()"></i>
                    </div>
                </div>
            </div>
        </div>
    `,
})
export class TopbarComponent extends AppComponentBase implements OnInit {
    items: MenuItem[] = [];
    promptLabel: string = "";
    clickedButton: boolean = false;
    isGenerateCode: boolean = false;

    private readonly routeMap = new Map<string, { label: string; isGenerateCode?: boolean }>([
        ['/app/main/qacodai/prompt/generate-code', { label: 'Gerar Código', isGenerateCode: true }],
        ['/app/main/qacodai/prompt/generate-code-review', { label: 'Revisão de Código', isGenerateCode: false }],
        ['/app/main/qacodai/prompt/generate-documentation', { label: 'Gerar Documentação', isGenerateCode: false }],
        ['/app/main/qacodai/prompt/functional-documentation', { label: 'Documentação Funcional', isGenerateCode: false }],
    ]);

    constructor(
        injector: Injector,
        private router: Router,
        private eventEmmiterService: EventEmitterService,
        private _promptAIConfigService: PromptAIConfigServiceProxy
    ) {
        super(injector);
        this.getAiConfigs();
    }

    ngOnInit() { }

    private setRouteByUrl() {
        const routeConfig = this.routeMap.get(this.router.url);
        this.promptLabel = routeConfig.label;
        this.isGenerateCode = routeConfig.isGenerateCode;
    }


    private async getAiConfigs() {
        this._promptAIConfigService
            .getPromptAISettings(this.appSession.tenantId)
            .subscribe(result => this.setPromptAISettingsOptions(result))
            .add(() => this.setRouteByUrl());
    }

    onClose() {
        this.router.navigateByUrl(`/app/main/myProfile`);
    }

    openClosedSidebar() {
        this.clickedButton = !this.clickedButton;
        this.eventEmmiterService.setClickButton(this.clickedButton);
    }

    private setPromptAISettingsOptions(config: PromptAIConfigDto) {
        const menuOptions = [
            { key: 'codeGenerate', label: 'Gerar Código', icon: 'fa fa-laptop', route: '/app/main/qacodai/prompt/generate-code' },
            { key: 'codeReview', label: 'Revisão de Código', icon: 'fa fa-code', route: '/app/main/qacodai/prompt/generate-code-review' },
            { key: 'documentation', label: 'Gerar Documentação', icon: 'fa fa-file', route: '/app/main/qacodai/prompt/generate-documentation' },
            { key: 'classDiagramReading', label: 'Documentação Funcional', icon: 'fa fa-file-pdf', route: '/app/main/qacodai/prompt/functional-documentation' },
        ];

        this.items = menuOptions
            .filter(option => config[option.key])
            .map(option => ({
                label: option.label,
                icon: option.icon,
                routerLink: [option.route],
                command: () => {
                    this.promptLabel = option.label;
                    if (option.key != 'codeGenerate') this.isGenerateCode = false;
                    else this.isGenerateCode = true;
                }
            }));
    }
}
