import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { PromptAIEnum } from "@app/shared/enums/prompt-ai-enum";
import {
    AIConversationHistoryDto,
    ChatTitlesDto,
    GenerateCodeRequest,
    PromptAIConfigServiceProxy,
} from "@shared/service-proxies/service-proxies";
import { MessageService } from "primeng/api";
import { FeedbackRequest } from "../../../../../../shared/service-proxies/service-proxies";
import * as moment from "moment";
import { EventEmitterService } from "../../../service/event-emmiter.service";
import { TypewriterComponent } from "@app/main/qacodai/layout/components/typewriter/typewriter.component";

interface ChatTitle {
    date: Date;
    aiPromptType: number;
    chatTitle: string;
}

interface GroupedChatTitles {
    [date: string]: ChatTitle[];
}

@Component({
    selector: "app-generate-code",
    templateUrl: "./generate-code.component.html",
    styleUrls: ["./generate-code.component.less"],
})
export class GenerateCodeComponent implements OnInit {
    @ViewChild("scroll") scrollContainer!: ElementRef;
    @ViewChild(TypewriterComponent) typewriterComponent!: TypewriterComponent;

    sidebarVisible: boolean = false;
    isOther: boolean = false;
    isCopy: boolean = false;
    loading: boolean = false;
    visible: boolean = false;
    chatId: string = "";
    chatReloadId: string = "";
    chatTitle: string = "";
    chatDate: string = "";
    isSimpleOrComplete: boolean = false;
    chatConversationHistory: AIConversationHistoryDto[] = [];
    generateDateRequest = {} as GenerateCodeRequest;
    feedbackRequest = {} as FeedbackRequest;
    loadingRegenerate: boolean = false;
    satisfactoryAnswer: boolean | null = null;
    userInput: string = "";
    userPrompt: string = "";
    userFeedbackInput: string = "";
    chatsTitles: ChatTitlesDto[] = [];
    chatsTitlesByDates: GroupedChatTitles = {};
    isStopText: boolean = false;

    constructor(
        private messageService: MessageService,
        private sanitizer: DomSanitizer,
        private eventEmmiterService: EventEmitterService,
        private _promptAIConfigService: PromptAIConfigServiceProxy
    ) {
        this.eventEmmiterService.getClickButton().subscribe((response: boolean) => {
            this.sidebarVisible = response;
        });
    }

    async ngOnInit() {
        await this.getTitlesSidebar();
    }

    async getChatHistory(chat: ChatTitle, date: moment.Moment) {
        this.chatDate = date.toISOString().split("T")[0];
        this.userFeedbackInput == "";
        this.loading = true;
        this.chatTitle = chat.chatTitle;
        await this._promptAIConfigService
            .getAiConversationHistory(chat.aiPromptType, date, chat.chatTitle)
            .subscribe(
                async (result) => {
                    this.chatConversationHistory = result;
                    setTimeout(() => {
                        this.scrollToBottom();
                    }, 0);
                },
                (error: any) => {
                    this.userInput = "";
                    this.loading = false;

                    this.messageService.add({
                        severity: "error",
                        summary: "Erro!",
                        detail: `Ocorreu um erro ao efetuar a sua requisição.`,
                        life: 3000,
                    });
                }
            )
            .add(() => {
                this.userInput = "";
                this.loading = false;
                this.sidebarVisible = false;
            });
    }

    async getTitlesSidebar() {
        this.loading = true;
        await this._promptAIConfigService
            .getChatTitle(PromptAIEnum.CodeGenerate)
            .subscribe(
                async (result) => {
                    if (result.length > 0) {
                        this.chatsTitles = result;
                        this.setTitlesChatsByDates(result);
                    }
                },
                (error: any) => {
                    this.loading = false;
                    this.messageService.add({
                        severity: "error",
                        summary: "Erro!",
                        detail: `Ocorreu um erro ao efetuar a sua requisição.`,
                        life: 3000,
                    });
                }
            )
            .add(() => this.loading = false);
    }

    setTitlesChatsByDates(chatsTitle: ChatTitlesDto[]) {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);

        const thirtyDaysAgo = new Date(today);
        thirtyDaysAgo.setDate(today.getDate() - 30);

        function parseDateString(dateString: string): Date {
            const [day, month, yearAndTime] = dateString.split("/");
            const [year] = yearAndTime.split(" ");
            return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
        }

        function getDateGroup(parsedDate: Date): string {
            const todayZeroed = new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate()
            );
            const yesterdayZeroed = new Date(
                yesterday.getFullYear(),
                yesterday.getMonth(),
                yesterday.getDate()
            );

            if (parsedDate >= todayZeroed) {
                return "Hoje";
            }
            if (parsedDate >= yesterdayZeroed && parsedDate < todayZeroed) {
                return "Ontem";
            }
            if (parsedDate >= thirtyDaysAgo) {
                return "Últimos 30 dias";
            }
            return "Últimos 30 dias";
        }

        var chatsTitlesByDates = chatsTitle.reduce(
            (acc: GroupedChatTitles, item: ChatTitlesDto) => {
                const parsedDate = parseDateString(
                    item.chatDate.format("DD/MM/YYYY")
                );
                const dateGroup = getDateGroup(parsedDate);

                if (!acc[dateGroup]) {
                    acc[dateGroup] = [];
                }
                const isDuplicate = acc[dateGroup].some(
                    (existingItem) => existingItem.chatTitle === item.chatTitle
                );

                if (!isDuplicate) {
                    acc[dateGroup].push({
                        date: item.chatDate.toDate(),
                        aiPromptType: item.aiPromptType,
                        chatTitle: item.chatTitle,
                    });
                }

                return acc;
            },
            {} as GroupedChatTitles
        );

        this.chatsTitlesByDates = chatsTitlesByDates;
    }

    truncateText(text: string, maxLength: number = 28): string {
        if (text != null && text.length > maxLength) {
            return text.substring(0, maxLength) + "...";
        }
        return text;
    }

    scrollToBottom(): void {
        try {
            if (this.scrollContainer && this.scrollContainer.nativeElement) {
                this.scrollContainer.nativeElement.scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                });
            }
        } catch (err) {
            console.error("Erro ao rolar o chat:", err);
        }
    }

    sanitizeText(message: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(message);
    }

    onCloseFeedback() {
        this.isOther = false;
        this.userFeedbackInput == "";
    }

    async setFeedbackUser(chatId: string, satisfaction: boolean) {
        this.isOther = false;
        this.satisfactoryAnswer = satisfaction;
        this.chatId = chatId;

        if (!satisfaction) {
            this.visible = true;
        } else {
            await this.setResponseFeedback();
        }
    }

    async setResponseFeedback() {
        this.feedbackRequest = this.setFeedbackRequest(
            this.chatId,
            this.satisfactoryAnswer,
            this.userFeedbackInput
        );
        if (this.userFeedbackInput == "" && !this.satisfactoryAnswer) {
            this.messageService.add({
                severity: "warn",
                summary: "Atenção!",
                detail: "Informe a justificativa.",
            });
        } else {
            this.loading = true;
            await this._promptAIConfigService
                .updateFeedback(this.feedbackRequest)
                .subscribe(
                    (result) => {
                        if (this.chatConversationHistory.length)
                            this.chatConversationHistory.filter((x) => {
                                if (x.id == this.chatId)
                                    x.satisfactoryAnswer =
                                        result.satisfactoryAnswer;
                                x.responseFeedback = result.responseFeedback;
                            });

                        this.messageService.add({
                            severity: "success",
                            summary: "Feedback Recebido!",
                            detail: "Obrigado pelo seu Feedback.",
                        });
                        this.userFeedbackInput = "";
                    },
                    (error: any) => {
                        this.resetUpdateFeedback();

                        this.messageService.add({
                            severity: "error",
                            summary: "Erro!",
                            detail: `Ocorreu um erro ao efetuar a sua requisição.`,
                            life: 3000,
                        });
                        this.userFeedbackInput = "";
                    }
                )
                .add(() => {
                    this.resetUpdateFeedback();
                });
        }
    }

    copyHTMLContent(message: string, chatReloadId: string = ""): void {
        this.isCopy = true;
        this.chatReloadId =
            chatReloadId != "" ? chatReloadId : this.chatReloadId;
        const tempTextarea = document.createElement("textarea");
        tempTextarea.value = message;
        document.body.appendChild(tempTextarea);
        tempTextarea.select();
        document.execCommand("copy");
        document.body.removeChild(tempTextarea);

        setTimeout(() => {
            this.isCopy = false;
        }, 1000);
    }

    setFeedbackRequest(
        chatId: string,
        satisfactoryAnswer: boolean,
        userFeedbackInput: string
    ): FeedbackRequest {
        this.feedbackRequest.respondeId = chatId;
        this.feedbackRequest.satisfaction = satisfactoryAnswer;
        this.feedbackRequest.userFeedback = userFeedbackInput;

        return this.feedbackRequest;
    }

    resetUpdateFeedback() {
        this.visible = false;
        this.userFeedbackInput == "";
        this.loading = false;
    }

    async regenerateQuestion(
        chat: AIConversationHistoryDto
    ) {
        this.userInput = chat.question;
        this.chatTitle = chat.chatTitle;
        this.chatReloadId = chat.id;
        this.loadingRegenerate = true;
        this.isStopText = false;

        await this._promptAIConfigService
            .regenerateQuestion(chat.id)
            .subscribe(
                async (result) => {
                    this.chatConversationHistory =
                        this.chatConversationHistory.filter(
                            (x) => x.id != chat.id
                        );
                    this.chatConversationHistory.push(result);
                    this.isStopText = true;
                    setTimeout(() => {
                        this.scrollToBottom();
                    }, 0);
                },
                (error: any) => {
                    this.loadingRegenerate = false;

                    this.messageService.add({
                        severity: "error",
                        summary: "Erro!",
                        detail: `Ocorreu um erro ao efetuar a sua requisição.`,
                        life: 3000,
                    });
                }
            )
            .add(() => {
                this.loadingRegenerate = false;
            });
    }

    async sendMessage() {
        if (this.userInput == "") {
            this.messageService.add({
                severity: "warn",
                summary: "Atenção!",
                detail: `Preencha o campo com sua pergunta antes de enviar.`,
                life: 3000,
            });
            return;
        } else {
            this.loading = true;
            this.isStopText = false;
            this.userPrompt = this.userInput;
            if (this.chatTitle == "") this.chatTitle = this.userInput;

            this.generateDateRequest.question = this.userInput;
            this.generateDateRequest.chatTitle = this.chatTitle;
            this.generateDateRequest.chatDate = this.chatDate;
            this.generateDateRequest.isSimpleOrComplete =
                this.isSimpleOrComplete;

            await this._promptAIConfigService
                .generateCompleteCode(this.generateDateRequest)
                .subscribe(
                    (result) => {
                        this.chatTitle = result.chatTitle;
                        this.chatConversationHistory.push(result);

                        setTimeout(() => {
                            this.scrollToBottom();
                        }, 0);
                    },
                    (error: any) => {
                        this.resetGenerateCode();

                        this.messageService.add({
                            severity: "error",
                            summary: "Erro!",
                            detail: `Ocorreu um erro ao efetuar a sua requisição.`,
                            life: 3000,
                        });
                    }
                )
                .add(() => {
                    this.resetGenerateCode();
                });
        }
    }

    resetGenerateCode() {
        this.loading = false;
        this.sidebarVisible = false;
        this.isSimpleOrComplete = false;
        this.getTitlesSidebar();
    }

    resetChat() {
        this.chatConversationHistory = [];
        this.chatTitle = "";
        this.chatDate = "";
        this.sidebarVisible = false;
    }

    async setFeedbackMessage(message: string) {
        this.userFeedbackInput = message;
        await this.setResponseFeedback();
    }

    stopTyping() {
        this.isStopText = true;
        if (this.typewriterComponent) {
            this.typewriterComponent.stopTyping();
        }
    }
}
