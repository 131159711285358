import * as ngCommon from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { AppUrlService } from "./nav/app-url.service";
import { AppUiCustomizationService } from "./ui/app-ui-customization.service";
import { AppSessionService } from "./session/app-session.service";
import { CookieConsentService } from "./session/cookie-consent.service";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { AbpModule } from "abp-ng2-module";

@NgModule({
    imports: [ngCommon.CommonModule, AbpModule, MatCheckboxModule],
})
export class CommonModule {
    static forRoot(): ModuleWithProviders<any> {
        return {
            ngModule: CommonModule,
            providers: [
                AppUiCustomizationService,
                CookieConsentService,
                AppSessionService,
                AppUrlService,
            ],
        };
    }
}
