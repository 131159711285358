import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AvaliacoesDeQualidadesComponent } from "./avaliacoesDeQualidade/avaliacoesDeQualidades/avaliacoesDeQualidades.component";
import { LiberacoesUsuariosComponent } from "./liberacoesUsuarios/liberacoesUsuarios/liberacoesUsuarios.component";
import { DemandasComponent } from "./demandas/demandas/demandas.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { KanbanComponent } from "./demandas/demandas/kanban/kanban.component";
import { ItensAvaliadosComponent } from "./avaliacoesDeQualidade/itens-avaliados/itens-avaliados.component";
import { GestaoMudancasComponent } from "./gestaoMudancas/gestaoMudancas.component";
import { AprovacoesComponent } from "./gestaoMudancas/aprovacoes/aprovacoes.component";
import { AvaliacaoAmbienteAgendamentoComponent } from "./avaliacaoAmbiente/agendamento/agendamento.component";
import { AvaliacaoAmbienteInventarioObjetosComponent } from "./avaliacaoAmbiente/inventarioObjetos/inventarioObjetos.component";
import { AvaliacaoAmbienteInformacaoObjetoComponent } from "./avaliacaoAmbiente/inventarioObjetos/infomacaoObjeto/infomacaoObjeto.component";
import { AvaliacaoAmbienteDebitosTecnicosDoObjetoComponent } from "./avaliacaoAmbiente/inventarioObjetos/infomacaoObjeto/debitosTecnicosDoObjeto/debitosTecnicosDoObjeto.component";
import { CodeReviewComponent } from "./codeReview/codeReview.component";
import { AvaliacoesCodeReviewComponent } from "./codeReview/avaliacoes/avaliacoes.component";
import { MyProfileComponent } from "./myProfile/myProfile.component";
import { AppComponent } from "..";
import { ConfigurationDeployComponent } from "./deploy/configurationDeploy.component";
import { ConfigurationProjetosComponent } from "./projeto/configurationProjeto.component";
import { ReportsHostTenantComponent } from "./dashboard/reports-host-tenant/reports-host-tenant.component";
import { ConfigurationQualityComponent } from "./avaliacoesDeQualidade/configuration/configuringQualityAssessment.component";
import { ConfigurationRiskManagementComponent } from "./riskManagement/configuration/configurationRiskManagement.component";
import { ApprovalHistoryComponent } from "./gestaoMudancas/historicoAprovacoes/historicoAprovacoes.component";
import { ChangeRequestConfigComponent } from "./changeRequest/changeRequestConfig.component";
import { CreateOrEditChangeRequestComponent } from "./changeRequest/createChangeRequest/list-change-request.component";
import { ApprovalChangeRequestComponent } from "./changeRequest/approvalChangeRequests/list-approval-change.component";
import { HistoryApprovalChangeRequestComponent } from "./changeRequest/historyChangeRequest/historyChangeRequestModal.component";
import { IntegratedTestsComponent } from "./IntegratedTests/integrated-tests.component";

//Componentes QACODAI
import { GenerateCodeComponent } from "./qacodai/pages/prompt/generate-code/generate-code.component";
import { GenerateCodeReviewComponent } from "./qacodai/pages/prompt/generate-code-review/generate-code-review.component";
import { GenerateDocumentationComponent } from "./qacodai/pages/prompt/generate-documentation/generate-documentation.component";
import { FunctionalDocumentationComponent } from "./qacodai/pages/prompt/functional-documentation/functional-documentation.component";
import { LayoutComponent } from "./qacodai/layout/components/layout.component";

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: "",
                children: [
                    {
                        path: "avaliacoes-qualidade/itens/:id",
                        component: ItensAvaliadosComponent,
                        data: { permission: "Pages.AvaliacoesDeQualidades" },
                    },
                    {
                        path: "avaliacoes-qualidade",
                        component: AvaliacoesDeQualidadesComponent,
                        data: { permission: "Pages.AvaliacoesDeQualidades" },
                    },
                    {
                        path: "quality-config",
                        component: ConfigurationQualityComponent,
                        data: {
                            permission:
                                "Pages.AvaliacoesDeQualidades.Configuration",
                        },
                    },
                    {
                        path: "liberacoes",
                        component: LiberacoesUsuariosComponent,
                        data: { permission: "Pages.LiberacoesUsuarios" },
                    },
                    {
                        path: "demandas/demandas",
                        component: DemandasComponent,
                        data: { permission: "Pages.Demandas" },
                    },
                    {
                        path: "demandas/kanban",
                        component: KanbanComponent,
                        data: { permission: "Pages.Demandas" },
                    },
                    {
                        path: "demandas/kanban/:demandaId",
                        component: KanbanComponent,
                        data: { permission: "Pages.Demandas" },
                    },
                    {
                        path: "dashboard",
                        component: DashboardComponent,
                        data: { permission: "Pages.Dashboards" },
                    },
                    {
                        path: "qacodai",
                        component: LayoutComponent,
                        children: [
                            {
                                path: "prompt/generate-code",
                                data: { breadcrumb: "Gerar Código" },
                                component: GenerateCodeComponent,
                            },
                            {
                                path: "prompt/generate-code-review",
                                data: {
                                    breadcrumb: "Gerar Revisão de Código",
                                },
                                component: GenerateCodeReviewComponent,
                            },
                            {
                                path: "prompt/generate-documentation",
                                data: {
                                    breadcrumb: "Gerar Documentação",
                                },
                                component: GenerateDocumentationComponent,
                            },
                            {
                                path: "prompt/functional-documentation",
                                data: {
                                    breadcrumb: "Documentação Funcional",
                                },
                                component: FunctionalDocumentationComponent,
                            },
                        ],
                    },
                    {
                        path: "relatorios",
                        component: ReportsHostTenantComponent,
                        data: { permission: "Pages.Dashboards" },
                    },
                    {
                        path: "gestaoMudancas/config",
                        component: GestaoMudancasComponent,
                        data: {
                            permission:
                                "Pages.Administration.ChangeManagement.Configuration",
                        },
                    },
                    {
                        path: "gestaoMudancas/aprovacoes",
                        component: AprovacoesComponent,
                        data: {
                            permission:
                                "Pages.Administration.ChangeManagement.Approval",
                        },
                    },
                    {
                        path: "gestaoMudancas/historicoAprovacoes",
                        component: ApprovalHistoryComponent,
                        data: {
                            permission:
                                "Pages.Administration.ChangeManagement.Approval",
                        },
                    },
                    {
                        path: "codeReview/config",
                        component: CodeReviewComponent,
                        data: {
                            permission: "Pages.Administration.ChangeManagement",
                        },
                    },
                    {
                        path: "codeReview/avaliacoes",
                        component: AvaliacoesCodeReviewComponent,
                        data: {
                            permission: "Pages.Administration.ChangeManagement",
                        },
                    },
                    {
                        path: "avaliacaoAmbiente/agendamento",
                        component: AvaliacaoAmbienteAgendamentoComponent,
                        data: {
                            permission: "Pages.AvaliacaoAmbiente.Configuration",
                        },
                    },
                    {
                        path: "avaliacaoAmbiente/inventario",
                        component: AvaliacaoAmbienteInventarioObjetosComponent,
                        data: {
                            permission: "Pages.AvaliacaoAmbiente.Configuration",
                        },
                    },
                    {
                        path: "avaliacaoAmbiente/inventario/informacaoObjeto/:name/:size",
                        component: AvaliacaoAmbienteInformacaoObjetoComponent,
                        data: {
                            permission: "Pages.AvaliacaoAmbiente.Configuration",
                        },
                    },
                    {
                        path: "avaliacaoAmbiente/inventario/informacaoObjeto/debitosTecnicos/:type/:name",
                        component:
                            AvaliacaoAmbienteDebitosTecnicosDoObjetoComponent,
                        data: {
                            permission: "Pages.AvaliacaoAmbiente.Configuration",
                        },
                    },
                    {
                        path: "myProfile",
                        component: MyProfileComponent,
                        data: { permission: "" },
                    },
                    {
                        path: "myProfile/:avaliacao",
                        component: AppComponent,
                        data: { permission: "" },
                    },
                    {
                        path: "deploy",
                        component: ConfigurationDeployComponent,
                        data: { permission: "" },
                    },
                    {
                        path: "projeto",
                        component: ConfigurationProjetosComponent,
                        data: { permission: "Pages.Projects.Configuration" },
                    },
                    {
                        path: "risk-config",
                        component: ConfigurationRiskManagementComponent,
                        data: {
                            permission:
                                "Pages.Administration.RiskManagement.Configuration",
                        },
                    },
                    {
                        path: "changeRequest/config",
                        component: ChangeRequestConfigComponent,
                        data: {
                            permission:
                                "Pages.Administration.ChangeRequest.Configuration",
                        },
                    },
                    {
                        path: "changeRequest/createChangeRequest/createChange",
                        component: CreateOrEditChangeRequestComponent,
                        data: {
                            permission:
                                "Pages.Administration.ChangeRequest.CreateChange",
                        },
                    },
                    {
                        path: "changeRequest/approvalChangeRequests/approvalChange",
                        component: ApprovalChangeRequestComponent,
                        data: {
                            permission:
                                "Pages.Administration.ChangeRequest.Approval",
                        },
                    },
                    {
                        path: "changeRequest/historyChangeRequest/historyChangeRequestModal",
                        component: HistoryApprovalChangeRequestComponent,
                        data: {
                            permission:
                                "Pages.Administration.ChangeRequest.Approval",
                        },
                    },
                    {
                        path: "integrated-test",
                        component: IntegratedTestsComponent,
                    },
                ],
            },
        ]),
    ],
    exports: [RouterModule],
})
export class MainRoutingModule {}
