<p-toast />
<app-spinner [isLoading]="loading"></app-spinner>
<div class="kt-login__form">
    <div class="d-flex flex-row">
        <section class="section-chat-prompt">
            <section class="section-history" *ngIf="sidebarVisible && chatsTitles.length">
                <div class="d-flex flex-column align-items-center">
                    <div class="d-flex flex-row justify-content-between mb-5 mt-2">
                        <button class="button-sidebar" (click)="resetChat()">New Chat</button>
                    </div>
                    <div class="charts-titles" *ngFor="let date of chatsTitlesByDates | keyvalue">
                        <span>{{date.key}}</span>
                        <p class="p-history" *ngFor="let chat of date.value" (click)="getChatHistory(chat, chat.date)">
                            {{truncateText(chat.chatTitle)}}
                        </p>
                    </div>
                </div>
            </section>
            <div class="wrapper" (click)="sidebarVisible = false">
                <div class="chat-all">
                    <div class="chat-messages">
                        <div *ngFor="let chat of chatConversationHistory, let last = last">
                            <div class="user-message" *ngIf="!loading">
                                <p [innerHTML]="chat.question"></p>
                            </div>
                            <div class="assistant-message">
                                <p-progressSpinner class="spinner-button"
                                    *ngIf="chat.id == chatReloadId && loadingRegenerate">
                                </p-progressSpinner>
                                <app-typewriter class="card p-4" [text]="chat.response"
                                    [isWrite]="userPrompt == chat.question ? true : false"
                                    [style]="chat.id == chatReloadId && loadingRegenerate ? 'display: none' : ''">
                                </app-typewriter>
                                <!-- <textarea pInputTextarea class="textarea-response" [autoResize]="true"
                                    [innerHTML]="sanitizeText(chat.response)" [readonly]="true"
                                    [style]="chat.id == chatReloadId && loadingRegenerate ? 'display: none' : ''">
                                </textarea> -->
                                <div class="feedback-items">
                                    <i class="fa fa-undo mr-2 icons-feedback" pTooltip="Regenerate"
                                        (click)="regenerateQuestion(chat)">
                                    </i>
                                    <i class="fa fa-thumbs-up mr-2 icons-feedback"
                                        *ngIf="chat.satisfactoryAnswer == null || chat.satisfactoryAnswer == true"
                                        [ngClass]="chat.satisfactoryAnswer == true ? 'feedback-selected' : ''"
                                        pTooltip="Good response"
                                        (click)="chat.satisfactoryAnswer == null ? setFeedbackUser(chat.id, true) : ''">
                                    </i>
                                    <i class="fa fa-thumbs-down mr-2 icons-feedback"
                                        *ngIf="chat.satisfactoryAnswer == null || chat.satisfactoryAnswer == false"
                                        [ngClass]="chat.satisfactoryAnswer == false ? 'feedback-selected' : ''"
                                        pTooltip="Bad response"
                                        (click)="chat.satisfactoryAnswer == null ? setFeedbackUser(chat.id, false) : ''">
                                    </i>
                                    <i class="fa fa-copy mr-2 icons-feedback" pTooltip="Copy"
                                        (click)="copyHTMLContent(chat.response, chat.id)" *ngIf="!isCopy">
                                    </i>
                                    <i class="fa fa-check mr-2 icons-feedback" pTooltip="Copy"
                                        *ngIf="chat.id == chatReloadId && isCopy">
                                    </i>
                                </div>
                            </div>
                            <div *ngIf="last" #scroll></div>
                        </div>
                    </div>
                </div>
                <div class="chat-question">
                    <div class="chat-textarea">
                        <textarea rows="8" (keydown.enter)="sendMessage()" pInputTextarea [(ngModel)]="userInput"
                            placeholder="Digite sua mensagem">
                        </textarea>
                    </div>
                </div>
                <div class="buttons">
                    <p-inputSwitch class="mr-2" [(ngModel)]="isSimpleOrComplete" pTooltip="Gerar Código">
                    </p-inputSwitch>
                    <button class="button-chat mr-2" (click)="sendMessage()" pTooltip="Enviar" tooltipPosition="top"
                        [disabled]="userInput == '' || loading || loadingRegenerate">
                        <i class="fa fa-arrow-up"></i>
                    </button>
                    <button class="button-chat mr-2" pTooltip="Parar" tooltipPosition="top" [disabled]="isStopText"
                        (click)="stopTyping()">
                        <i class="fa fa-stop"></i>
                    </button>
                </div>
            </div>
        </section>
    </div>
</div>

<p-dialog header="Header" [(visible)]="visible" [style]="{ width: '60vw' }" [modal]="true" (onHide)="onCloseFeedback()">
    <ng-template pTemplate="header">
        <span class="text-xl font-bold">Conte-nos mais?</span>
    </ng-template>
    <div style="padding: 2rem 3rem 0 3rem">
        <div class="dialog-inputs mt-2">
            <input pInputText class="col-4 mr-2 p-2 input-feedback" type="text" value="O código estava incorreto"
                [readonly]="true" (click)="setFeedbackMessage('O código estava incorreto')" />
            <input pInputText class="col-4 mr-2 p-2 input-feedback" type="text" value="Recusei quando não deveria"
                [readonly]="true" (click)="setFeedbackMessage('Recusei quando não deveria')" />
            <input pInputText class="col-4 p-2 input-feedback" type="text" value="Não gostei do estilo"
                (click)="setFeedbackMessage('Não gostei do estilo')" />
        </div>
        <div class="dialog-inputs mt-2 mb-4">
            <input pInputText class="col-6 mr-2 p-2 input-feedback" type="text"
                value="Não segui as instruções completamente" [readonly]="true"
                (click)="setFeedbackMessage('Não segui as instruções completamente')" />
            <input pInputText class="col-4 mr-2 p-2 input-feedback" type="text" value="Não está correto de fato"
                [readonly]="true" (click)="setFeedbackMessage('Não está correto de fato')" />
            <input pInputText class="col-2 p-2 input-feedback" type="text" value="Outro..." (click)="isOther = true"
                [readonly]="true" />
        </div>
    </div>
    <div class="dialog-justification mt-5" *ngIf="isOther">
        <label class="col-10">Justificativa</label>
        <textarea pInputTextarea class="textarea-feedback" [autoResize]="true"
            placeholder="(Opcional) Sinta-se à vontade para adicionar detalhes específicos"
            [(ngModel)]="userFeedbackInput">
        </textarea>
    </div>
    <ng-template class="dialog-footer" pTemplate="footer">
        <button [class]="userFeedbackInput == '' && !isOther ? 'button-modal-disabled' : 'button-modal'"
            (click)="setResponseFeedback()" [disabled]="userFeedbackInput == '' && !isOther">
            <i class="fa fa-share mr-1"></i> <span>Enviar</span>
        </button>
    </ng-template>
</p-dialog>
