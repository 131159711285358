import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
    CodeRequest,
    GenerateCodeRequest,
    PromptAIConfigServiceProxy,
} from "@shared/service-proxies/service-proxies";
import { MessageService, PrimeNGConfig, SelectItem } from "primeng/api";
import { FeedbackRequest } from "../../../../../../shared/service-proxies/service-proxies";
import { PdfReaderService } from "@shared/services/pdf-reader.service";
import { TypewriterComponent } from "@app/main/qacodai/layout/components/typewriter/typewriter.component";

@Component({
    selector: "app-functional-documentation",
    templateUrl: "./functional-documentation.component.html",
    styleUrls: ["./functional-documentation.component.less"],
})
export class FunctionalDocumentationComponent implements OnInit {
    @ViewChild("scroll") scrollContainer!: ElementRef;
    @ViewChild(TypewriterComponent) typewriterComponent!: TypewriterComponent;

    items: SelectItem[] = [];
    isTechnicalSpecification: boolean = false;
    isUml: boolean = false;
    loading: boolean = false;
    visible: boolean = false;
    isOther: boolean = false;
    textResponse: string = "";
    userInput: string = "";
    isCopy: boolean = false;
    showModal: boolean = false;
    file: File | null = null;
    chatDate: string = "";
    userFeedbackInput: string = "";
    chatId: string = "";
    generateCodeRequest = {} as GenerateCodeRequest;
    codeRequest = {} as CodeRequest;
    feedbackRequest = {} as FeedbackRequest;
    satisfactoryAnswer: boolean | null = null;
    textExtract: string = "";
    isStopText: boolean = false;
    isUpload: boolean = true;

    constructor(
        private messageService: MessageService,
        private pdfReaderService: PdfReaderService,
        private config: PrimeNGConfig,
        private _promptAIConfigService: PromptAIConfigServiceProxy
    ) { }

    ngOnInit() { }

    showDialog() {
        this.showModal = !this.showModal;
    }

    formatSize(bytes) {
        const k = 1024;
        const dm = 3;
        const sizes = this.config.translation.fileSizeTypes;
        if (bytes === 0) {
            return `0 ${sizes[0]}`;
        }

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        const formattedSize = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

        return `${formattedSize} ${sizes[i]}`;
    }

    async onUploaded(data: { files: File }) {
        this.file = data.files[0];
        const MAX_SIZE_MB = 5 * 1024 * 1024;

        if (this.file.size > MAX_SIZE_MB) {
            this.messageService.add({
                severity: "warn",
                summary: "Atenção!",
                detail: `Limite máximo do arquivo é 5mb.`,
                life: 3000,
            });
            this.removeFile();
        } else {
            this.items = [];
            this.textResponse = "";

            const textExtracts = await this.pdfReaderService.extractTextFromPdf(
                this.file
            );

            textExtracts.forEach((element) => {
                this.textExtract += element;
            });

            if (this.isTechnicalSpecification) {
                this.textExtract = this.technicalSpecificationRegex(this.textExtract);
            }
        }
    }

    setTypeFile(isUmlFile: boolean) {
        this.isUml = isUmlFile;
        this.isTechnicalSpecification = !isUmlFile;
    }

    removeFile() {
        this.file = null;
        this.isUpload = false;
        this.textExtract = '';
        this.textResponse = '';
        this.isUml = false;
        this.isTechnicalSpecification = false;

        setTimeout(() => {
            this.isUpload = true;
        }, 5)
    }

    extractFunctionalDescriptions(text: string): string {
        const matches = text.match(
            /3.2   DESCRIÇÃO DAS FUNCIONALIDADES  Criar([\s\S]*?)ENDIF\./g
        );
        matches ? matches.map((match) => match.trim()) : [];
        return matches[0];
    }

    technicalSpecificationRegex(text: string): string {
        text = this.extractFunctionalDescriptions(text);
        text = text.replace(
            "ESPECIFICAÇÃO FUNCIONAL E TÉCNICA  <Projeto>  ",
            ""
        );
        text = text.replace("3.2   DESCRIÇÃO DAS FUNCIONALIDADES", "");
        text = text
            .replace("Pág. 1  ", "")
            .replace("Pág. 2  ", "")
            .replace("Pág. 3  ", "");
        text = text
            .replace("Pág. 4  ", "")
            .replace("Pág. 5  ", "")
            .replace("Pág. 6  ", "");
        text = text
            .replace("Pág. 7  ", "")
            .replace("Pág. 8  ", "")
            .replace("Pág. 9  ", "");
        text = text.replace("Pág. 10  ", "").replace("Pág. 11  ", "");

        return text;
    }

    async readDocAndGenerateCode() {
        this.textExtract = this.userInput + '\n' + this.textExtract;

        if (this.isTechnicalSpecification || this.file == null) {
            this.generateCodeRequest.question = this.textExtract;
            this.generateCodeRequest.chatTitle =
                "Documentação Funcional - text";
            this.generateCodeRequest.chatDate = this.chatDate;
            this.generateCodeRequest.isSimpleOrComplete = false;

            this.loading = true;
            this.isStopText = false;

            await this._promptAIConfigService
                .generateCompleteCode(this.generateCodeRequest)
                .subscribe(
                    (result) => {
                        this.textResponse = result.response;
                        this.chatId = result.id;

                        if (this.file) {
                            this.setItemsArchiveApiQACODAI(result.response);
                        }
                        else {
                            this.items.push({
                                value: this.setValueInterface(result.response),
                                label: 'Código gerado',
                            });
                        }
                    },
                    (error: any) => {
                        this.loading = false;

                        this.messageService.add({
                            severity: "error",
                            summary: "Erro!",
                            detail: `Ocorreu um erro ao efetuar a sua requisição.`,
                            life: 3000,
                        });
                    }
                )
                .add(() => {
                    this.loading = false;
                });
        } else {
            this.items = [];
            const typePrompt: string = this.isUml ? "uml" : "text";
            this.codeRequest.question = this.textExtract;

            if (this.file && !this.isTechnicalSpecification && !this.isUml) {
                this.messageService.add({
                    severity: "warn",
                    summary: "Atenção!",
                    detail: "Informe o tipo do documento ou remova o anexo.",
                });
                return;
            }

            this.loading = true;
            this.isStopText = false;

            await this._promptAIConfigService
                .readDocAndGenerateCode(typePrompt, this.codeRequest)
                .subscribe(
                    (result) => {
                        this.textResponse = result.response;
                        this.chatId = result.id;
                        this.setItemsArchiveApiQACODAI(result.response);

                        this.messageService.add({
                            severity: "success",
                            summary: "Sucesso!",
                            detail: "Arquivo documentado com sucesso.",
                        });
                    },
                    (error: any) => {
                        this.loading = false;

                        this.messageService.add({
                            severity: "error",
                            summary: "Erro!",
                            detail: `Ocorreu um erro ao efetuar a sua requisição.`,
                            life: 3000,
                        });
                    }
                )
                .add(() => {
                    this.loading = false;
                });
        }
    }

    setLabelInterface(response: string) {
        const regexx = /^(INTERFACE.*?)(?<!\.)$/m;
        const output = response.replace(regexx, "$1.");

        const regex = /INTERFACE\s+(.*?)\./i;
        const matchInterface = output.match(regex);
        return matchInterface ? matchInterface[1] : "";
    }

    setValueInterface(response: string): string {
        const regex = /INTERFACE([\s\S]*?)ENDINTERFACE/gim;
        const match = response.match(regex);

        return match ? match[0] : "";
    }

    setItemsArchiveApiQACODAI(response: string) {
        this.items.push({
            value: this.setValueInterface(response),
            label: this.setLabelInterface(response),
        });

        const classSections: string[] = [];
        let match;

        const classRegex = /CLASS([\s\S]*?)ENDCLASS/g;
        while ((match = classRegex.exec(response)) !== null) {
            classSections.push(match[0].trim());
        }

        classSections.map(async (text: string) => {
            this.setItemsArchive(text);
        });
    }

    setItemsArchive(response: string) {
        let classText: string = "Class";

        const regex = /CLASS\s+(.*?)\s+DEFINITION/i;
        const match = regex.exec(response);

        if (match) {
            classText = match[1].trim();
        } else {
            const regex = /CLASS\s+(.*?)\s+IMPLEMENTATION/i;
            const match = regex.exec(response);

            if (match) classText = match[1].trim();
        }

        this.items.push({
            value: response,
            label: classText.replace("```", ""),
        });
    }

    async setFeedbackUser(chatId: string, satisfaction: boolean) {
        this.isOther = false;
        this.satisfactoryAnswer = satisfaction;
        this.chatId = chatId;

        if (!satisfaction) {
            this.visible = true;
        } else {
            await this.setResponseFeedback();
        }
    }

    setFeedbackRequest(
        chatId: string,
        satisfactoryAnswer: boolean,
        userFeedbackInput: string
    ): FeedbackRequest {
        this.feedbackRequest.respondeId = chatId;
        this.feedbackRequest.satisfaction = satisfactoryAnswer;
        this.feedbackRequest.userFeedback = userFeedbackInput;

        return this.feedbackRequest;
    }

    async setResponseFeedback() {
        this.feedbackRequest = this.setFeedbackRequest(
            this.chatId,
            this.satisfactoryAnswer,
            this.userFeedbackInput
        );
        if (this.userFeedbackInput == "" && !this.satisfactoryAnswer) {
            this.messageService.add({
                severity: "warn",
                summary: "Atenção!",
                detail: "Informe a justificativa.",
            });
        } else {
            this.loading = true;
            await this._promptAIConfigService
                .updateFeedback(this.feedbackRequest)
                .subscribe(
                    (result) => {
                        this.messageService.add({
                            severity: "success",
                            summary: "Feedback Recebido!",
                            detail: "Obrigado pelo seu Feedback.",
                        });
                        this.userFeedbackInput = "";
                    },
                    (error: any) => {
                        this.resetUpdateFeedback();

                        this.messageService.add({
                            severity: "error",
                            summary: "Erro!",
                            detail: `Ocorreu um erro ao efetuar a sua requisição.`,
                            life: 3000,
                        });
                        this.userFeedbackInput = "";
                    }
                )
                .add(() => {
                    this.resetUpdateFeedback();
                });
        }
    }

    resetUpdateFeedback() {
        this.visible = false;
        this.userFeedbackInput == "";
        this.loading = false;
    }

    onCloseFeedback() {
        this.isOther = false;
        this.userFeedbackInput == "";
    }

    async setFeedbackMessage(message: string) {
        this.userFeedbackInput = message;
        await this.setResponseFeedback();
    }

    copyHTMLContent(message: string): void {
        this.isCopy = true;
        const tempTextarea = document.createElement("textarea");
        tempTextarea.value = message;
        document.body.appendChild(tempTextarea);
        tempTextarea.select();
        document.execCommand("copy");
        document.body.removeChild(tempTextarea);

        setTimeout(() => {
            this.isCopy = false;
        }, 1000);
    }

    stopTyping() {
        this.isStopText = true;
        if (this.typewriterComponent) {
            this.typewriterComponent.stopTyping();
        }
    }
}
