import { Component, Injector, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AppComponentBase } from "@shared/common/app-component-base";
import {
    ConfigTimeSavingDto,
    ConfigTimeSavingServiceProxy,
    DashTenantUsersDto,
    DashTenantUsersServiceProxy,
} from "@shared/service-proxies/service-proxies";
import { MenuItem, MessageService } from "primeng/api";

@Component({
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.less"],
})
export class DashboardComponent extends AppComponentBase implements OnInit {
    items: MenuItem[] | undefined;
    loading: boolean = false;
    visible: boolean = false;
    isRework: boolean = false;
    isCostReduction: boolean = false;
    isNonCompliance: boolean = false;
    isGmud: boolean = false;
    isRequest: boolean = false;
    isTimeline: boolean = false;
    isSaving: boolean = false;
    isReqChanges: boolean = false;
    isIntegratedTests: boolean = false;
    isResults: boolean = false;
    isGraphics: boolean = true;
    isEnvironmentalAssessment: boolean = false;

    isSavingCreator: boolean = false;

    isReworkMenu: boolean = false;
    isCostReductionMenu: boolean = false;
    isNonComplianceMenu: boolean = false;
    isGmudMenu: boolean = false;
    isRequestMenu: boolean = false;
    isTimelineMenu: boolean = false;
    isSavingMenu: boolean = false;
    isReqChangesMenu: boolean = false;
    isIntegratedTestsMenu: boolean = false;
    isEnvironmentAssessmentMenu: boolean = false;
    isMenu: boolean = true;

    activeIndex: number = 0;
    configTimeSavingForm: FormGroup;
    configTimeSaving = {} as ConfigTimeSavingDto;
    copyTransportTime: number = 3;
    productiveTransportTime: number = 7;
    professionalPrice: number = 150;

    constructor(
        injector: Injector,
        private fb: FormBuilder,
        private _dashTenantUserServiceProxy: DashTenantUsersServiceProxy,
        private _configTimeSavingServiceProxy: ConfigTimeSavingServiceProxy,
        private messageService: MessageService
    ) {
        super(injector);
        this.createForm();
    }

    ngOnInit() {
        if (this.appSession.tenantId != null)
            this.getConfigTimeSaving(this.appSession.tenantId);

        this.createMenu();
    }

    get getConfigTimeSavingForm() {
        return this.configTimeSavingForm.controls;
    }

    createForm() {
        this.configTimeSavingForm = this.fb.group({
            userId: [this.appSession.userId],
            tenantId: [this.appSession.tenantId],
            copyTransportTime: [0, [Validators.required]],
            productiveTransportTime: [0, Validators.required],
            professionalPrice: [0, Validators.required],
        });
    }

    async getConfigTimeSaving(tenantId: number) {
        await this._configTimeSavingServiceProxy
            .getConfigTimeSavingByTenantId(tenantId)
            .subscribe((res) => {
                this.configTimeSaving = res;

                if (res.productiveTransportTime != 0) {
                    this.isSavingCreator = true;
                    this.updateTimesAndPrice(res);
                }
            });
    }

    async onSubmit() {
        this.configTimeSaving = { ...this.configTimeSavingForm.value };
        this.isSaving = false;
        if (this.isSavingCreator)
            await this._configTimeSavingServiceProxy
                .updateConfigTimeSaving(this.configTimeSaving)
                .subscribe((res) => {
                    if (res) {
                        this.messageService.add({
                            severity: "success",
                            summary: "Successo!",
                            detail: "Atualizado com sucesso.",
                        });
                        this.updateTimesAndPrice(res);
                    }
                })
                .add(() => this.updateModalAndComponentSavinGlobal(false));
        else
            await this._configTimeSavingServiceProxy
                .createConfigTimeSaving(this.configTimeSaving)
                .subscribe((res) => {
                    this.configTimeSaving = res;
                    this.updateTimesAndPrice(res);
                    this.messageService.add({
                        severity: "success",
                        summary: "Successo!",
                        detail: "Salvo com sucesso.",
                    });
                })
                .add(() => this.updateModalAndComponentSavinGlobal(true));
    }

    updateModalAndComponentSavinGlobal(index: boolean) {
        this.activeIndex = 0;
        this.visible = false;
        this.isSaving = true;
        this.isSavingCreator = index;
    }

    updateTimesAndPrice(configTimeSavingDto: ConfigTimeSavingDto) {
        this.copyTransportTime = configTimeSavingDto.copyTransportTime;
        this.professionalPrice = configTimeSavingDto.professionalPrice;
        this.productiveTransportTime =
            configTimeSavingDto.productiveTransportTime;
    }

    async changeOption(indice: number, status: boolean) {
        this.loading = true;

        await this._dashTenantUserServiceProxy
            .updateDashTenant(
                this.appSession.configDashs.userId,
                indice,
                status
            )
            .subscribe((res) => {
                if (res) {
                    switch (indice) {
                        case 0:
                            this.appSession.configDashs.isRework = status;
                            break;
                        case 1:
                            this.appSession.configDashs.isCostReduction =
                                status;
                            break;
                        case 2:
                            this.appSession.configDashs.isNonCompliance =
                                status;
                            break;
                        case 3:
                            this.appSession.configDashs.isGmud = status;
                            break;
                        case 4:
                            this.appSession.configDashs.isRequest = status;
                            break;
                        case 5:
                            this.appSession.configDashs.isEnvironmentalAssessment =
                                status;
                            break;
                        case 6:
                            this.appSession.configDashs.isSaving = status;
                            break;
                        case 7:
                            this.appSession.configDashs.isTimeline = status;
                            break;
                        case 8:
                            this.appSession.configDashs.isReqChanges = status;
                            break;
                        case 9:
                            this.appSession.configDashs.isIntegratedTests = status;
                            break;
                    }
                }
                this.resultMessage(res, this.appSession.configDashs);
            })
            .add(() => {
                this.loading = false;
                this.createMenu();
            });
    }

    resultMessage(result: boolean, configDashs: DashTenantUsersDto) {
        result
            ? this.messageService.add({
                severity: "success",
                summary: "Successo!",
                detail: "Salvo com sucesso.",
            })
            : this.messageService.add({
                severity: "warn",
                summary: "Alerta!",
                detail: "Configurações não alteradas.",
            });

        this.validMenu(configDashs);
    }

    setIndexTab(activeIndex: number) {
        if (activeIndex == 0 && this.appSession.configDashs.isRework) {
            this.isRework = true;
            this.isCostReduction = false;
            this.isNonCompliance = false;
            this.isGmud = false;
            this.isRequest = false;
            this.isTimeline = false;
            this.isSaving = false;
            this.isReqChanges = false;
            this.isIntegratedTests = false;
            this.isEnvironmentalAssessment = false;
        }

        if (activeIndex == 1 && this.appSession.configDashs.isCostReduction) {
            this.isRework = false;
            this.isCostReduction = true;
            this.isNonCompliance = false;
            this.isGmud = false;
            this.isRequest = false;
            this.isTimeline = false;
            this.isSaving = false;
            this.isReqChanges = false;
            this.isIntegratedTests = false;
            this.isEnvironmentalAssessment = false;
        }

        if (activeIndex == 2 && this.appSession.configDashs.isNonCompliance) {
            this.isRework = false;
            this.isCostReduction = false;
            this.isNonCompliance = true;
            this.isGmud = false;
            this.isRequest = false;
            this.isTimeline = false;
            this.isSaving = false;
            this.isReqChanges = false;
            this.isIntegratedTests = false;
            this.isEnvironmentalAssessment = false;
        }

        if (activeIndex == 3 && this.appSession.configDashs.isGmud) {
            this.isRework = false;
            this.isCostReduction = false;
            this.isNonCompliance = false;
            this.isGmud = true;
            this.isRequest = false;
            this.isTimeline = false;
            this.isSaving = false;
            this.isReqChanges = false;
            this.isIntegratedTests = false;
            this.isEnvironmentalAssessment = false;
        }

        if (activeIndex == 4 && this.appSession.configDashs.isRequest) {
            this.isRework = false;
            this.isCostReduction = false;
            this.isNonCompliance = false;
            this.isGmud = false;
            this.isRequest = true;
            this.isTimeline = false;
            this.isSaving = false;
            this.isReqChanges = false;
            this.isIntegratedTests = false;
            this.isEnvironmentalAssessment = false;
        }

        if (
            activeIndex == 5 &&
            this.appSession.configDashs.isEnvironmentalAssessment
        ) {
            this.isRework = false;
            this.isCostReduction = false;
            this.isNonCompliance = false;
            this.isGmud = false;
            this.isRequest = false;
            this.isTimeline = false;
            this.isSaving = false;
            this.isReqChanges = false;
            this.isIntegratedTests = false;
            this.isEnvironmentalAssessment = true;
        }

        if (activeIndex == 6 && this.appSession.configDashs.isTimeline) {
            this.isRework = false;
            this.isCostReduction = false;
            this.isNonCompliance = false;
            this.isGmud = false;
            this.isRequest = false;
            this.isTimeline = true;
            this.isSaving = false;
            this.isReqChanges = false;
            this.isIntegratedTests = false;
            this.isEnvironmentalAssessment = false;
        }

        if (activeIndex == 7 && this.appSession.configDashs.isSaving) {
            this.isRework = false;
            this.isCostReduction = false;
            this.isNonCompliance = false;
            this.isGmud = false;
            this.isRequest = false;
            this.isTimeline = false;
            this.isSaving = true;
            this.isReqChanges = false;
            this.isIntegratedTests = false;
            this.isEnvironmentalAssessment = false;
        }

        if (activeIndex == 11 && this.appSession.configDashs.isReqChanges) {
            this.isRework = false;
            this.isCostReduction = false;
            this.isNonCompliance = false;
            this.isGmud = false;
            this.isRequest = false;
            this.isTimeline = false;
            this.isSaving = false;
            this.isReqChanges = true;
            this.isIntegratedTests = false;
            this.isEnvironmentalAssessment = false;
        }

        if (activeIndex == 12 && this.appSession.configDashs.isIntegratedTests) {
            this.isRework = false;
            this.isCostReduction = false;
            this.isNonCompliance = false;
            this.isGmud = false;
            this.isRequest = false;
            this.isTimeline = false;
            this.isSaving = false;
            this.isReqChanges = false;
            this.isIntegratedTests = true;
            this.isEnvironmentalAssessment = false;
        }

        if (activeIndex == 8) {
            this.isGraphics = true;
            this.isSaving = false;
            this.isResults = false;
        }

        if (activeIndex == 9) {
            this.isGraphics = false;
            this.isSaving = true;
            this.isResults = false;
        }

        if (activeIndex == 10) {
            this.isGraphics = false;
            this.isSaving = false;
            this.isResults = true;
        }
    }

    createMenu() {
        if (this.appSession.tenantId != null) {
            this.validDashs(this.appSession.configDashs);
            this.items = [
                {
                    label: "Retrabalho",
                    disabled: !this.isReworkMenu,
                    command: () => this.setIndexTab(0),
                },
                {
                    label: "Análise Code Review",
                    disabled: !this.isCostReductionMenu,
                    command: () => this.setIndexTab(1),
                },
                {
                    label: "Não Conformidade",
                    disabled: !this.isNonComplianceMenu,
                    command: () => this.setIndexTab(2),
                },
                {
                    label: `Gmud's`,
                    disabled: !this.isGmudMenu,
                    command: () => this.setIndexTab(3),
                },
                {
                    label: `Request's`,
                    disabled: !this.isRequestMenu,
                    command: () => this.setIndexTab(4),
                },
                {
                    label: `Avaliação de Ambiente`,
                    disabled: !this.isEnvironmentAssessmentMenu,
                    command: () => this.setIndexTab(5),
                },
                {
                    label: "Timeline Demandas",
                    disabled: !this.isTimelineMenu,
                    command: () => this.setIndexTab(6),
                },
                {
                    label: "Saving Global",
                    disabled: !this.isSavingMenu,
                    command: () => this.setIndexTab(7),
                },
                {
                    label: "Requisições de Mudança",
                    disabled: !this.isReqChangesMenu,
                    command: () => this.setIndexTab(11),
                },
                // Será habilitado após criação de Dashboard's Testes Integrados
                // {
                //     label: "Testes Integrados",
                //     disabled: !this.isIntegratedTestsMenu,
                //     command: () => this.setIndexTab(12),
                // },
            ];
        } else {
            this.items = [
                {
                    label: "Gráficos",
                    disabled: false,
                    command: () => this.setIndexTab(8),
                },
                {
                    label: "Saving Global",
                    disabled: false,
                    command: () => this.setIndexTab(9),
                },
                {
                    label: "Resultados Obtidos",
                    disabled: false,
                    command: () => this.setIndexTab(10),
                },
            ];
        }
    }

    validDashs(configDashs: DashTenantUsersDto) {
        this.isReworkMenu = configDashs.isRework;
        this.isCostReductionMenu = configDashs.isCostReduction;
        this.isNonComplianceMenu = configDashs.isNonCompliance;
        this.isGmudMenu = configDashs.isGmud;
        this.isRequestMenu = configDashs.isRequest;
        this.isTimelineMenu = configDashs.isTimeline;
        this.isSavingMenu = configDashs.isSaving;
        this.isReqChangesMenu = configDashs.isReqChanges;
        this.isIntegratedTestsMenu = configDashs.isIntegratedTests;
        this.isEnvironmentAssessmentMenu =
            configDashs.isEnvironmentalAssessment;

        if (configDashs.isRework) {
            this.isRework = true;
            this.setIndexTab(0);
        } else {
            if (configDashs.isCostReduction) {
                this.isCostReduction = true;
                this.setIndexTab(1);
            }
            if (configDashs.isNonCompliance && !configDashs.isCostReduction) {
                this.isNonCompliance = true;
                this.setIndexTab(2);
            }
            if (
                configDashs.isGmud &&
                !configDashs.isNonCompliance &&
                !configDashs.isCostReduction
            ) {
                this.isGmud = true;
                this.setIndexTab(3);
            }
            if (
                configDashs.isRequest &&
                !configDashs.isGmud &&
                !configDashs.isTimeline &&
                !configDashs.isNonCompliance &&
                !configDashs.isCostReduction
            ) {
                this.isRequest = true;
                this.setIndexTab(4);
            }
            if (
                configDashs.isEnvironmentalAssessment &&
                !configDashs.isSaving &&
                !configDashs.isReqChanges &&
                !configDashs.isIntegratedTests &&
                !configDashs.isRequest &&
                !configDashs.isTimeline &&
                !configDashs.isGmud &&
                !configDashs.isNonCompliance &&
                !configDashs.isCostReduction
            ) {
                this.isEnvironmentalAssessment = true;
                this.setIndexTab(5);
            }
            if (
                configDashs.isTimeline &&
                !configDashs.isSaving &&
                !configDashs.isReqChanges &&
                !configDashs.isIntegratedTests &&
                !configDashs.isRequest &&
                !configDashs.isGmud &&
                !configDashs.isNonCompliance &&
                !configDashs.isCostReduction
            ) {
                this.isTimeline = true;
                this.setIndexTab(6);
            }
            if (
                configDashs.isSaving &&
                !configDashs.isTimeline &&
                !configDashs.isReqChanges &&
                !configDashs.isIntegratedTests &&
                !configDashs.isRequest &&
                !configDashs.isGmud &&
                !configDashs.isNonCompliance &&
                !configDashs.isCostReduction
            ) {
                this.isSaving = true;
                this.setIndexTab(7);
            }

            if (
                configDashs.isReqChanges &&
                !configDashs.isSaving &&
                !configDashs.isTimeline &&
                !configDashs.isIntegratedTests &&
                !configDashs.isRequest &&
                !configDashs.isGmud &&
                !configDashs.isNonCompliance &&
                !configDashs.isCostReduction
            ) {
                this.isReqChanges = true;
                this.setIndexTab(11);
            }

            if (
                configDashs.isIntegratedTests &&
                !configDashs.isSaving &&
                !configDashs.isTimeline &&
                !configDashs.isReqChanges &&
                !configDashs.isRequest &&
                !configDashs.isGmud &&
                !configDashs.isNonCompliance &&
                !configDashs.isCostReduction
            ) {
                this.isIntegratedTests = true;
                this.setIndexTab(12);
            }
        }

        this.validMenu(configDashs);
    }

    validMenu(configDashs: DashTenantUsersDto) {
        if (
            !configDashs.isRework &&
            !configDashs.isCostReduction &&
            !configDashs.isSaving &&
            !configDashs.isRequest &&
            !configDashs.isTimeline &&
            !configDashs.isNonCompliance &&
            !configDashs.isGmud &&
            !configDashs.isEnvironmentalAssessment
        )
            this.isMenu = false;
    }
}
