import { Component, Input, OnInit } from "@angular/core";
import { SelectItem } from "primeng/api";

@Component({
    selector: "app-archive-list",
    templateUrl: "./archive-list.component.html",
    styleUrls: ["./archive-list.component.less"],
})
export class ArchiveListComponent implements OnInit {
    @Input() archives: SelectItem[] = [];
    responsiveOptions: any[] = [];

    constructor() {}

    ngOnInit() {
        this.responsiveOptions = [
            {
                breakpoint: "1199px",
                numVisible: 1,
                numScroll: 1,
            },
            {
                breakpoint: "991px",
                numVisible: 2,
                numScroll: 1,
            },
            {
                breakpoint: "767px",
                numVisible: 1,
                numScroll: 1,
            },
        ];
    }

    downloadTextFile(selectedArchive: any) {
        const blob = new Blob([selectedArchive.value], { type: "text/plain" });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = selectedArchive.label;
        link.click();

        window.URL.revokeObjectURL(url);
    }
}
