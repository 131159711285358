import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import {
    AIConversationHistoryDto,
    CodeRequest,
    PromptAIConfigServiceProxy,
} from "@shared/service-proxies/service-proxies";
import { MessageService } from "primeng/api";
import { FeedbackRequest } from "../../../../../../shared/service-proxies/service-proxies";
import { Router } from "@angular/router";
import { TypewriterComponent } from "@app/main/qacodai/layout/components/typewriter/typewriter.component";

@Component({
    selector: "app-generate-code-review",
    templateUrl: "./generate-code-review.component.html",
    styleUrls: ["./generate-code-review.component.less"],
})
export class GenerateCodeReviewComponent implements OnInit {
    @ViewChild("scroll") scrollContainer!: ElementRef;
    @ViewChild(TypewriterComponent) typewriterComponent!: TypewriterComponent;

    feedbackRequest = {} as FeedbackRequest;
    codeRequest = {} as CodeRequest;
    userInput: string = "";
    userFeedbackInput: string = "";
    chatConversation: AIConversationHistoryDto;
    isOther: boolean = false;
    isCopy: boolean = false;
    visibleCodeModal: boolean = true;
    loading: boolean = false;
    satisfactoryAnswer: boolean | null = null;
    chatId: string = "";
    textResponse: string = "";
    visible: boolean = false;
    isStopText: boolean = false;

    constructor(
        private messageService: MessageService,
        public router: Router,
        private _promptAIConfigService: PromptAIConfigServiceProxy
    ) { }

    ngOnInit() { }

    async sendMessageNoPrompt() {
        if (this.userInput) {
            if (this.userInput == "") {
                this.messageService.add({
                    severity: "warn",
                    summary: "Atenção!",
                    detail: `Adicione o código que deseja documentar.`,
                    life: 3000,
                });
                return;
            } else {
                this.loading = true;
                this.codeRequest.question = this.userInput;
                this.isStopText = false;

                await this._promptAIConfigService
                    .performCodeReview(this.codeRequest)
                    .subscribe(
                        async (result) => {
                            this.chatId = result.id;
                            this.chatConversation = result;
                            this.textResponse = result.response;
                        },
                        (error: any) => {
                            this.loading = false;

                            this.messageService.add({
                                severity: "error",
                                summary: "Erro!",
                                detail: `Ocorreu um erro ao efetuar a sua requisição.`,
                                life: 3000,
                            });
                        }
                    )
                    .add(() => {
                        this.loading = false;
                    });
            }
        } else {
            this.messageService.add({
                severity: "info",
                summary: "Alerta!",
                detail: `Documentação está inconsistente? Clique em Regenerate para gerar novamente.`,
                life: 3000,
            });
            return;
        }
    }

    async setFeedbackUser(chatId: string, satisfaction: boolean) {
        this.isOther = false;
        this.satisfactoryAnswer = satisfaction;
        this.chatId = chatId;

        if (!satisfaction) {
            this.visible = true;
        } else {
            await this.setResponseFeedback();
        }
    }

    async regenerateQuestionNoPrompt(message: string) {
        this.userInput = message;
        await this.regenerateQuestion(this.chatConversation);
    }

    async regenerateQuestion(chat: AIConversationHistoryDto) {
        this.loading = true;
        this.userInput = chat.question;
        this.isStopText = false;

        await this._promptAIConfigService
            .regenerateQuestion(chat.id)
            .subscribe(
                async (result) => {
                    this.chatId = result.id;
                    this.chatConversation = result;
                    this.satisfactoryAnswer = result.satisfactoryAnswer;
                    this.textResponse = result.response;
                },
                (error: any) => {
                    this.loading = false;

                    this.messageService.add({
                        severity: "error",
                        summary: "Erro!",
                        detail: `Ocorreu um erro ao efetuar a sua requisição.`,
                        life: 3000,
                    });
                }
            )
            .add(() => {
                this.loading = false;
            });
    }

    scrollToBottom(): void {
        try {
            if (this.scrollContainer && this.scrollContainer.nativeElement) {
                this.scrollContainer.nativeElement.scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                });
            }
        } catch (err) {
            console.error("Erro ao rolar o chat:", err);
        }
    }

    async setFeedbackMessage(message: string) {
        this.userFeedbackInput = message;
        await this.setResponseFeedback();
    }

    async setResponseFeedback() {
        this.feedbackRequest = this.setFeedbackRequest(
            this.chatId,
            this.satisfactoryAnswer,
            this.userFeedbackInput
        );
        if (this.userFeedbackInput == "" && !this.satisfactoryAnswer) {
            this.messageService.add({
                severity: "warn",
                summary: "Atenção!",
                detail: "Informe a justificativa.",
            });
        } else {
            this.loading = true;
            await this._promptAIConfigService
                .updateFeedback(this.feedbackRequest)
                .subscribe(
                    (result) => {
                        this.messageService.add({
                            severity: "success",
                            summary: "Feedback Recebido!",
                            detail: "Obrigado pelo seu Feedback.",
                        });
                        this.userFeedbackInput = "";
                    },
                    (error: any) => {
                        this.resetUpdateFeedback();

                        this.messageService.add({
                            severity: "error",
                            summary: "Erro!",
                            detail: `Ocorreu um erro ao efetuar a sua requisição.`,
                            life: 3000,
                        });
                        this.userFeedbackInput = "";
                    }
                )
                .add(() => {
                    this.resetUpdateFeedback();
                });
        }
    }

    resetUpdateFeedback() {
        this.visible = false;
        this.userFeedbackInput == "";
        this.loading = false;
    }

    setFeedbackRequest(
        chatId: string,
        satisfactoryAnswer: boolean,
        userFeedbackInput: string
    ): FeedbackRequest {
        this.feedbackRequest.respondeId = chatId;
        this.feedbackRequest.satisfaction = satisfactoryAnswer;
        this.feedbackRequest.userFeedback = userFeedbackInput;

        return this.feedbackRequest;
    }

    copyHTMLContent(message: string): void {
        this.isCopy = true;
        const tempTextarea = document.createElement("textarea");
        tempTextarea.value = message;
        document.body.appendChild(tempTextarea);
        tempTextarea.select();
        document.execCommand("copy");
        document.body.removeChild(tempTextarea);

        setTimeout(() => {
            this.isCopy = false;
        }, 1000);
    }

    onCloseFeedback() {
        this.isOther = false;
        this.userFeedbackInput == "";
    }

    stopTyping() {
        this.isStopText = true;
        if (this.typewriterComponent) {
            this.typewriterComponent.stopTyping();
        }
    }
}
