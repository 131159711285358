import { SidebarModule } from "primeng/sidebar";
import { ListboxModule } from "primeng/listbox";
import { MenuModule } from "primeng/menu";
import { DividerModule } from "primeng/divider";
import { MessagesModule } from "primeng/messages";
import { ButtonModule } from "primeng/button";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { TreeTableModule } from "primeng/treetable";
import { BreadcrumbModule } from "primeng/breadcrumb";
import { TagModule } from "primeng/tag";
import { AutoCompleteModule } from "primeng/autocomplete";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { PaginatorModule } from "primeng/paginator";
import { EditorModule } from "primeng/editor";
import { InputMaskModule } from "primeng/inputmask";
import { FileUploadModule } from "primeng/fileupload";
import { TableModule } from "primeng/table";
import { TreeModule } from "primeng/tree";
import { PanelModule } from "primeng/panel";
import { CheckboxModule } from "primeng/checkbox";
import { CardModule } from "primeng/card";
import { AccordionModule } from "primeng/accordion";
import { InputSwitchModule } from "primeng/inputswitch";
import { MenubarModule } from "primeng/menubar";
import { DialogModule } from "primeng/dialog";
import { TimelineModule } from "primeng/timeline";
import { CalendarModule } from "primeng/calendar";
import { TabViewModule } from "primeng/tabview";
import { ToastModule } from "primeng/toast";
import { SplitButtonModule } from "primeng/splitbutton";
import { CarouselModule } from "primeng/carousel";

export const PrimeNgModule = [
    AccordionModule,
    FileUploadModule,
    MenubarModule,
    CarouselModule,
    SplitButtonModule,
    DialogModule,
    DividerModule,
    CardModule,
    MenuModule,
    SidebarModule,
    InputTextareaModule,
    InputSwitchModule,
    TimelineModule,
    CheckboxModule,
    TagModule,
    ToastModule,
    PanelModule,
    ButtonModule,
    AutoCompleteModule,
    PaginatorModule,
    ListboxModule,
    ProgressSpinnerModule,
    EditorModule,
    InputMaskModule,
    TableModule,
    TreeModule,
    InputTextModule,
    TabViewModule,
    TreeTableModule,
    BreadcrumbModule,
    CalendarModule,
    MessagesModule,
];
